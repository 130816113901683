import {
  BugOutlined,
  CalendarOutlined,
  DatabaseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Divider, FormInstance, Tooltip, Typography } from 'antd'
import { t } from 'i18next'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import Badge from '../../../../components/display/Badge'
import InfoSection from '../../../../components/display/InfoSection'
import { BadgeColors } from '../../../../constants/constants'
import {
  Document,
  SearchQuery,
} from '../../../../store/reducers/searchReviewReducer'
import ModalFormReviewSection from '../../../DataStoreListPage/modals/component/ModalFormReviewSection'

interface ProcessDeviationModalReviewStepProps {
  form: FormInstance
  selectedSearchQuery?: SearchQuery
  includedResults: Document[]
}

const { Text } = Typography

const BadgesContainer = styled.div`
  display: flex;
  gap: 8px;
`

const DeviationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DeviationText = styled(Text)`
  flex: 1;
  margin-right: 16px;
`

const DeviationBadges = styled.div`
  display: flex;
  gap: 4px;
  flex-shrink: 0;
`
const StyledDivider = styled(Divider)`
  margin-top: 0;
  margin-bottom: 0;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
`

const FixedHeader = styled.div`
  flex-shrink: 0;
`

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  padding-right: 8px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-bg-container);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-border);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-text-quaternary);
  }
`

const TitleText = styled.div`
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  margin-bottom: 8px;
`

const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const InfoIcon = styled(InfoCircleOutlined)`
  color: var(--color-text-quaternary);
  font-size: 14px;
  cursor: help;
`

const ProcessDeviationModalReviewStep = memo(
  ({
    form,
    selectedSearchQuery,
    includedResults,
  }: ProcessDeviationModalReviewStepProps) => {
    const workspaceTitle = useMemo(
      () => form.getFieldValue('workspace')?.label || '',
      [form]
    )

    return (
      <ModalContent>
        <FixedHeader>
          <ModalFormReviewSection
            label={t('processDeviationModal.steps.review.workspaceTitle')}
            value={workspaceTitle}
          />
          <StyledDivider />
          <InfoSection
            label={t('processDeviationModal.steps.review.searchQueryTitle')}
          >
            <TitleText>{selectedSearchQuery?.searchText}</TitleText>
            <BadgesContainer>
              <Badge
                color={BadgeColors.DATA_SOURCE}
                icon={<DatabaseOutlined />}
              >
                {selectedSearchQuery?.searchSource?.title}
              </Badge>
              <Badge color={BadgeColors.FILTERS} icon={<CalendarOutlined />}>
                {selectedSearchQuery?.searchParameters.date}
              </Badge>
            </BadgesContainer>
            <div>
              {t('processDeviationModal.steps.review.totalResults', {
                count: includedResults.length,
              })}
            </div>
          </InfoSection>
          <StyledDivider />
        </FixedHeader>

        <ScrollableContent>
          <InfoSection
            label={
              <SectionTitleContainer>
                {t('processDeviationModal.steps.review.deviationsTitle')}
                <Tooltip
                  title={t(
                    'processDeviationModal.steps.review.deviationsTooltip'
                  )}
                >
                  <InfoIcon />
                </Tooltip>
              </SectionTitleContainer>
            }
          >
            {includedResults.map((result, index) => (
              <DeviationRow key={result.id}>
                <DeviationText ellipsis={{ tooltip: result.title }}>
                  {result.title}
                </DeviationText>
                <DeviationBadges>
                  {result.tagDetails &&
                    Object.keys(result.tagDetails).map((key) => (
                      <Badge
                        icon={<BugOutlined />}
                        color={BadgeColors.ROOT_CAUSE_DISCOVERY}
                      >
                        {key ||
                          t('processDeviationModal.steps.review.noRootCause')}
                      </Badge>
                    ))}
                </DeviationBadges>
              </DeviationRow>
            ))}
          </InfoSection>
        </ScrollableContent>
      </ModalContent>
    )
  }
)

export default ProcessDeviationModalReviewStep
