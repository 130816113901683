import { ofrDocumentTemplateService } from '.'
import { workspaceTypes } from '../constants/constants'
import {
  AlgorithmRun,
  BiomarkConfigValues,
  DelConfigValues,
  DeviationClassificationConfig,
  QualityReviewConfigValues,
  RegulatorySubmissionConfigValues,
  WorkspaceConfig,
  WorkspaceDetails,
} from '../store/reducers/workspaceReducer'
// Service functions

const getBiomarkConfig = (
  algorithmRun: AlgorithmRun,
  selectedWorkspace: WorkspaceDetails
): BiomarkConfigValues | undefined => {
  return algorithmRun.config as BiomarkConfigValues
}

const getRegsubConfig = (
  algorithmRun: AlgorithmRun
): RegulatorySubmissionConfigValues | undefined => {
  return algorithmRun.config as RegulatorySubmissionConfigValues
}

const getDelConfig = (
  algorithmRun: AlgorithmRun
): DelConfigValues | undefined => {
  return algorithmRun.config as DelConfigValues
}

const getDeviationClassificationConfigById = async (
  deviationId?: string,
  selectedWorkspace?: WorkspaceDetails
): Promise<DeviationClassificationConfig | undefined> => {
  if (!deviationId || !selectedWorkspace) return undefined
  const workspaceConfig = selectedWorkspace.config as WorkspaceConfig
  const deviations = workspaceConfig.riskReliefConfig?.deviations
  if (!deviations) return undefined
  const deviation = deviations.find(
    (deviation) => deviation.searchResultId === deviationId
  )
  if (!deviation || !deviation.urn || !deviation.searchResultId) return
  
  return {
    deviations: [
      {
        id: deviation.searchResultId,
        urn: deviation.urn,
      },
    ],
  } as DeviationClassificationConfig
}
const getOfrQrConfig = async (
  selectedWorkspace: WorkspaceDetails
): Promise<QualityReviewConfigValues | undefined> => {
  const documentTemplateId = selectedWorkspace?.config?.ofrDocumentTemplatesId
  if (!documentTemplateId) return undefined

  try {
    const response = await ofrDocumentTemplateService.getOfrDocumentTemplate({
      documentId: documentTemplateId,
    })

    if (!response) return undefined

    return {
      documentTemplateId: response.documentId,
      documentTemplateFormPages: response.formPages,
      documentTemplateName:
        response.title || selectedWorkspace?.config?.ofrDocumentTemplatesName,
    } as QualityReviewConfigValues
  } catch (error) {
    console.error('Error fetching document template:', error)
    return undefined
  }
}

const getBaseAlgorithmConfig = (
  algorithmRun: AlgorithmRun,
  selectedWorkspace: WorkspaceDetails
) => {
  if (!algorithmRun || !selectedWorkspace) return undefined

  switch (selectedWorkspace.typeCode) {
    case workspaceTypes.BIOMARK:
      return getBiomarkConfig(algorithmRun, selectedWorkspace)
    case workspaceTypes.REGSUB:
      return getRegsubConfig(algorithmRun)
    case workspaceTypes.DEL:
      return getDelConfig(algorithmRun)
    default:
      return undefined
  }
}

const getAlgorithmConfig = async (
  typeCode: string,
  workspace: WorkspaceDetails,
  deviationId?: string,
  algorithmRun?: AlgorithmRun
) => {
  switch (typeCode) {
    case workspaceTypes.RISK_REL:
      if (!deviationId) {
        console.error('Missing deviationId for RISK_REL workspace')
        return undefined
      }
      return getDeviationClassificationConfigById(deviationId, workspace)

    case workspaceTypes.OFR_QR: {
      const config = await getOfrQrConfig(workspace)
      if (!config) {
        return undefined
      }
      return config
    }

    case workspaceTypes.REFAI:
      return undefined

    default:
      if (!algorithmRun) return undefined
      return getBaseAlgorithmConfig(algorithmRun, workspace)
  }
}

const smartFunctionActionService = {
  getBiomarkConfig,
  getRegsubConfig,
  getDelConfig,
  getDeviationClassificationConfigById,
  getOfrQrConfig,
  getAlgorithmConfig,
}

export default smartFunctionActionService
