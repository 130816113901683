import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Badge,
  ListPageLayout,
  TableBoldColumnContainer,
} from '../../../components'
import {
  BadgeColors,
  NO_DATA_PLACEHOLDER,
  RiskReliefDeviationStatus,
  dateFormat,
  riskReliefDeviationStatuses,
} from '../../../constants/constants'
import { useSearchReviews, useUsers, useWorkspaceDetails } from '../../../hooks'
import workspaceService from '../../../services/workspaceService'
import {
  SearchQuery,
  SearchReview,
} from '../../../store/reducers/searchReviewReducer'
import {
  Deviation,
  HistoricalReviewConfigDeviation,
  WorkspaceConfig,
} from '../../../store/reducers/workspaceReducer'
import { routePaths } from '../../RootPage'
import { workSpaceTabs, workspacePanelTypes } from '../WorkspaceDetailsPage'
interface ExtendedDeviation extends Deviation {
  key: string
  ownerName: string
  title: string
  searchReviewTitle?: string
  searchQueryText?: string
  documentTitle?: string
}

interface WorkspaceDeviationsPanelProps {
  statusFilter: RiskReliefDeviationStatus
  deviationOwner: string
}

interface SearchReviewDict {
  [key: string]: SearchReview
}

interface SearchQueryDict {
  [key: string]: SearchQuery
}

const WorkspaceDeviationsPanel = ({
  statusFilter,
  deviationOwner,
}: WorkspaceDeviationsPanelProps) => {
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const { findUser } = useUsers({})

  const { getSearchReviewsBatch } = useSearchReviews({
    preventFetch: true,
  })
  const [searchReviews, setSearchReviews] = useState<SearchReviewDict>({})
  const [searchQueries, setSearchQueries] = useState<SearchQueryDict>({})
  const [loading, setLoading] = useState(true)
  const [reviewsLoading, setReviewsLoading] = useState(false)

  const buildDictionaries = (reviews: SearchReview[]) => {
    return reviews.reduce<{
      reviewsDict: SearchReviewDict
      queriesDict: SearchQueryDict
    }>(
      (acc, review) => {
        if (!review) return acc

        acc.reviewsDict[review.id] = review

        review.searchQueries?.forEach((query) => {
          if (query?.id) {
            acc.queriesDict[query.id] = query
          }
        })

        return acc
      },
      { reviewsDict: {}, queriesDict: {} }
    )
  }

  const processDeviations = useCallback(
    (
      reviews: SearchReview[],
      queriesDict: SearchQueryDict,
      workspace: typeof selectedWorkspace
    ) => {
      const existingDeviations =
        workspace?.config?.riskReliefConfig?.deviations || []
      const deviationKeys = new Set(
        existingDeviations.map(
          (d) => `${d.searchReviewId}-${d.searchQueryId}-${d.searchResultId}`
        )
      )

      return reviews.flatMap((review) => {
        return (
          review.searchQueries?.flatMap((query) => {
            return (
              review.includedSearchResults?.flatMap((resultId) => {
                const isSameDataSource =
                  query?.searchSource?.id === selectedWorkspace?.dataSource?.id
                if (!isSameDataSource) return []
                const key = `${review.id}-${query.id}-${resultId}`
                if (deviationKeys.has(key)) return []
                const result = queriesDict[query.id]?.searchResults?.find(
                  (searchResult) => searchResult.id === resultId
                )
                if (!result?.summary) return []

                return [
                  {
                    searchReviewId: review.id,
                    searchQueryId: query.id,
                    searchResultId: resultId,
                    open: true,
                    deviationStatus: riskReliefDeviationStatuses.OPENED,
                  },
                ]
              }) || []
            )
          }) || []
        )
      })
    },
    [selectedWorkspace]
  )

  const handleWorkspaceUpdate = useCallback(
    async (
      workspace: typeof selectedWorkspace,
      newDeviations: HistoricalReviewConfigDeviation[]
    ) => {
      if (!newDeviations.length || !workspace) return

      const updatedConfig = {
        ...workspace.config,
        riskReliefConfig: {
          ...workspace.config?.riskReliefConfig,
          deviations: [
            ...(workspace.config?.riskReliefConfig?.deviations || []),
            ...newDeviations,
          ],
        },
      }

      try {
        await workspaceService.updateWorkspaceDetails({
          workspaceId: workspace.id,
          config: updatedConfig as WorkspaceConfig,
        })
      } catch (error) {
        console.error('Failed to update workspace with new deviations:', error)
      }
    },
    []
  )

  const fetchSearchReviewData = useCallback(async () => {
    const searchReviewIds =
      selectedWorkspace?.config?.riskReliefConfig?.searchReviewIds
    if (!searchReviewIds?.length) {
      setLoading(false)
      return
    }

    try {
      setReviewsLoading(true)

      const fetchedReviews = await getSearchReviewsBatch(searchReviewIds)
      const { reviewsDict, queriesDict } = buildDictionaries(fetchedReviews)

      const newDeviations = processDeviations(
        fetchedReviews,
        queriesDict,
        selectedWorkspace
      )

      await handleWorkspaceUpdate(selectedWorkspace, newDeviations)

      setSearchReviews(reviewsDict)
      setSearchQueries(queriesDict)
    } catch (error) {
      console.error('Error fetching search review data:', error)
    } finally {
      setLoading(false)
      setReviewsLoading(false)
    }
  }, [
    selectedWorkspace,
    getSearchReviewsBatch,
    processDeviations,
    handleWorkspaceUpdate,
  ])

  useEffect(() => {
    fetchSearchReviewData()
  }, [fetchSearchReviewData])

  const formattedData: ExtendedDeviation[] = useMemo(() => {
    const deviations =
      selectedWorkspace?.config?.riskReliefConfig?.deviations || []
    return deviations
      .filter((deviation: HistoricalReviewConfigDeviation) => {
        const matchesStatus =
          deviation.deviationStatus === statusFilter ||
          statusFilter === riskReliefDeviationStatuses.ALL

        const searchReview = searchReviews[deviation.searchReviewId]
        const matchesOwner =
          deviationOwner === 'all' ||
          searchReview?.createdBy?.id === deviationOwner
        return matchesStatus && matchesOwner
      })
      .map((deviation: HistoricalReviewConfigDeviation) => {
        const searchReview = searchReviews[deviation.searchReviewId]
        const searchQuery = searchQueries[deviation.searchQueryId]
        const document = searchQuery?.searchResults?.find(
          (result) => result.id === deviation.searchResultId
        )
        if (!document) {
          return undefined
        }
        return {
          ...deviation,
          id: deviation.searchResultId,
          key: `${deviation.searchReviewId}-${deviation.searchQueryId}-${deviation.searchResultId}`,
          ownerName:
            findUser(searchReview.createdBy?.id ?? '')?.displayName ?? '',
          title: document.title,
          searchReviewTitle: searchReview.title,
          searchQueryText: searchQuery.searchText,
          documentTitle: document.title,
          publicationDate: document.publicationDate,
          deviationStatus:
            deviation.deviationStatus ?? riskReliefDeviationStatuses.OPENED,
        } as unknown as ExtendedDeviation
      })
      .filter((item): item is ExtendedDeviation => item !== undefined)
  }, [
    selectedWorkspace,
    statusFilter,
    deviationOwner,
    searchReviews,
    searchQueries,
    findUser,
  ])

  const columns: ColumnsType<ExtendedDeviation> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '40%',
      render: (text, record) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Search Review'),
      dataIndex: 'searchReviewTitle',
      key: 'searchReviewTitle',
      width: '20%',
      render: (text) => text || NO_DATA_PLACEHOLDER,
    },
    {
      title: t('Search Query'),
      dataIndex: 'searchQueryText',
      key: 'searchQueryText',
      width: '20%',
      render: (text) => text || NO_DATA_PLACEHOLDER,
    },
    {
      title: t('Date'),
      dataIndex: 'publicationDate',
      key: 'publicationDate',
      render: (textDate) => (
        <>
          {textDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {textDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: t('Owner'),
      dataIndex: 'ownerName',
      key: 'ownerName',
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: t('Status'),
      dataIndex: 'deviationStatus',
      key: 'deviationStatus',
      render: (text) => (
        <>
          {text ? (
            <Badge
              color={
                text === riskReliefDeviationStatuses.OPENED
                  ? BadgeColors.STATUS_OPENED
                  : BadgeColors.STATUS_CLOSED
              }
            >
              {t(`workspaceDetailsPage.deviationStatus.${text}`)}
            </Badge>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
  ]

  const handleClick = (record: ExtendedDeviation) => {
    navigate(
      `${routePaths.WORKSPACE_DETAILS}/${workspaceId}/${workspacePanelTypes.OVERVIEW}/${workSpaceTabs.INFO}/${record.id}`
    )
  }

  return (
    <ListPageLayout>
      <Table
        data-testid="data-sources-list-table"
        rowClassName="page-list-table-row clickable"
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        loading={loading || reviewsLoading}
        scroll={{ y: 'calc(100vh - 170px)' }}
        onRow={(record) => ({
          onClick: () => handleClick(record),
        })}
      />
    </ListPageLayout>
  )
}

export default WorkspaceDeviationsPanel
