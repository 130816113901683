import { FileTextOutlined } from '@ant-design/icons'
import { Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../../components'
import {
  BACK_END_PULL_INTERVAL,
  NO_DATA_PLACEHOLDER,
  RiskReliefSmartFunctionType,
  algorithmRunStatuses,
  dateFormat,
  riskReliefSmartFunctionTypes,
  workspaceTypes,
} from '../../../constants/constants'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'
import useWorkspaces from '../../../hooks/useWorkspaces'
import {
  AlgorithmRun,
  DeviationClassificationConfig,
  QualityReviewConfigValues,
  clearSelectedAlgorithmRun,
} from '../../../store/reducers/workspaceReducer'
import { AlgorithmRunStatus } from '../../../types/generalTypes'
import { routePaths } from '../../RootPage'
import SmartFunctionActionDropdown from '../../SmartFunctions/components/SmartFunctionActionDropdown/SmartFunctionActionDropdown'

const ListPanelLayout = styled.div`
  height: calc(100vh - 124px);
  border-radius: 16px 16px 0px 0px;
`

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 124px);
`

interface ExtendedAlgorithmRun extends AlgorithmRun {
  key: string
  smartFunctionName?: string
  algorithmRunIndex?: number
  statusText?: string
  isStatusOngoing?: boolean
  smartFunctionType?: RiskReliefSmartFunctionType
}

interface WorkspaceSmartFunctionPanelProps {
  singleWorkspace?: boolean
}

const WorkspaceSmartFunctionPanel = ({
  singleWorkspace,
}: WorkspaceSmartFunctionPanelProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { workspaceId, deviationId } = useParams()
  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const { loading } = useWorkspaces({ workspaceId, preventFetch: true })
  const isStatusOngoing = useCallback(
    (runs: AlgorithmRun[]) =>
      !!runs?.find(
        (run) =>
          run.runStatus === algorithmRunStatuses.ONGOING_INGESTION ||
          run.runStatus === algorithmRunStatuses.ONGOING_AI
      ),
    []
  )

  const getStatusText = useCallback(
    (status: AlgorithmRunStatus | undefined) =>
      status
        ? t(`workspaceDetailsPage.status.${status}`)
        : t(`workspaceDetailsPage.status.${algorithmRunStatuses.READY}`) ||
          NO_DATA_PLACEHOLDER,
    []
  )

  const algorithmRunBase: ExtendedAlgorithmRun = useMemo(
    () => ({
      id: '0',
      key: '0',
      workspace: selectedWorkspace?.title || '',
      dataSource:
        typeof selectedWorkspace?.dataSource === 'string'
          ? selectedWorkspace?.dataSource
          : selectedWorkspace?.dataSource?.id,
      runStatus: algorithmRunStatuses.READY,
      statusText: t(
        `workspaceDetailsPage.status.${algorithmRunStatuses.READY}`
      ),
      smartFunctionName: selectedWorkspace?.algorithmName || '',
    }),
    [selectedWorkspace]
  )

  const nodesRuns = useMemo(
    () =>
      selectedWorkspace?.algorithmRuns && selectedWorkspace?.algorithmRuns[0]
        ? selectedWorkspace?.algorithmRuns[0].nodesRuns
        : undefined,
    [selectedWorkspace]
  )

  const getRecord = useCallback(
    (record: ExtendedAlgorithmRun) =>
      selectedWorkspace?.typeCode === workspaceTypes.RISK_REL &&
      selectedWorkspace.algorithmRuns &&
      selectedWorkspace.algorithmRuns[0]
        ? selectedWorkspace.algorithmRuns[0]
        : record,
    [selectedWorkspace]
  )

  const getRecordId = useCallback(
    (recordId: string) =>
      selectedWorkspace?.typeCode === workspaceTypes.RISK_REL
        ? selectedWorkspace.algorithmRuns && selectedWorkspace.algorithmRuns[0]
          ? selectedWorkspace.algorithmRuns[
              selectedWorkspace.algorithmRuns.length - 1
            ].id
          : '0'
        : recordId,
    [selectedWorkspace]
  )

  const riskReliefSmartFunctions: ExtendedAlgorithmRun[] = useMemo(() => {
    const runs = selectedWorkspace?.algorithmRuns?.filter((run) => {
      const config: DeviationClassificationConfig =
        run?.config as DeviationClassificationConfig
      return !!config?.deviations && config?.deviations[0]?.id === deviationId
    })
    return [
      {
        ...algorithmRunBase,
        id: '1',
        key: '1',
        smartFunctionName: t(
          'workspaceDetailsPage.riskReliefSmartFunction.deviationClassification'
        ),
        smartFunctionType:
          riskReliefSmartFunctionTypes.DEVIATION_CLASSIFICATION,
        algorithmRunIndex: runs ? runs.length : 0,
        runStatus: runs ? runs[runs.length - 1]?.runStatus : undefined,
        statusText: getStatusText(
          runs ? runs[runs.length - 1]?.runStatus : undefined
        ),
        isStatusOngoing: runs ? isStatusOngoing(runs) : false,
        actionDate: runs ? runs[runs.length - 1]?.actionDate : undefined,
      },
      {
        ...algorithmRunBase,
        id: '2',
        key: '2',
        smartFunctionName: t(
          'workspaceDetailsPage.riskReliefSmartFunction.rootCauseDiscovery'
        ),
        smartFunctionType: riskReliefSmartFunctionTypes.ROOT_CAUSE_DISCOVERY,
        algorithmRunIndex: nodesRuns?.rootCauseDiscovery
          ? nodesRuns?.rootCauseDiscovery.length
          : 0,
        statusText: getStatusText(
          nodesRuns?.rootCauseDiscovery
            ? nodesRuns?.rootCauseDiscovery[0]?.runStatus
            : undefined
        ),
        isStatusOngoing: nodesRuns?.rootCauseDiscovery
          ? isStatusOngoing(nodesRuns?.rootCauseDiscovery)
          : false,
        actionDate: nodesRuns?.rootCauseDiscovery
          ? nodesRuns?.rootCauseDiscovery[0]?.actionDate
          : undefined,
      },
      {
        ...algorithmRunBase,
        id: '3',
        key: '3',
        smartFunctionName: t(
          'workspaceDetailsPage.riskReliefSmartFunction.capaGeneration'
        ),
        smartFunctionType: riskReliefSmartFunctionTypes.CAPA_GENERATION,
        algorithmRunIndex: nodesRuns?.capaGeneration
          ? nodesRuns?.capaGeneration.length
          : 0,
        statusText: getStatusText(
          nodesRuns?.capaGeneration
            ? nodesRuns?.capaGeneration[0]?.runStatus
            : undefined
        ),
        isStatusOngoing: nodesRuns?.capaGeneration
          ? isStatusOngoing(nodesRuns?.capaGeneration)
          : false,
        actionDate: nodesRuns?.capaGeneration
          ? nodesRuns?.capaGeneration[0]?.actionDate
          : undefined,
      },
    ]
  }, [
    algorithmRunBase,
    getStatusText,
    isStatusOngoing,
    nodesRuns,
    selectedWorkspace,
    deviationId,
  ])

  const formattedData: ExtendedAlgorithmRun[] = useMemo(() => {
    const defaultRun: ExtendedAlgorithmRun = algorithmRunBase
    if (selectedWorkspace?.typeCode === workspaceTypes.RISK_REL) {
      return riskReliefSmartFunctions
    } else {
      const runs = selectedWorkspace?.algorithmRuns
      return !!runs && runs.length > 0
        ? runs.map((run: AlgorithmRun, index: number) => ({
            ...run,
            key: run.id,
            smartFunctionName: selectedWorkspace?.algorithmName ?? '',
            algorithmRunIndex: index + 1,
            statusText: run?.runStatus
              ? t(`workspaceDetailsPage.status.${run?.runStatus}`) ||
                NO_DATA_PLACEHOLDER
              : NO_DATA_PLACEHOLDER,
            isStatusOngoing: selectedWorkspace?.algorithmRuns
              ? isStatusOngoing(selectedWorkspace?.algorithmRuns)
              : false,
          }))
        : [defaultRun]
    }
  }, [
    selectedWorkspace,
    isStatusOngoing,
    algorithmRunBase,
    riskReliefSmartFunctions,
  ])

  useEffect(() => {
    const interval = setInterval(() => {
      refreshWorkspaceDetails()
    }, BACK_END_PULL_INTERVAL)

    return () => clearInterval(interval) // Clear the interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: ColumnsType<ExtendedAlgorithmRun> = [
    {
      title: t('Name'),
      dataIndex: 'smartFunctionName',
      key: 'smartFunctionName',
      width: '40%',
      render: (text, record) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Date'),
      dataIndex: 'actionDate',
      key: 'actionDate',
      width: '15%',
      render: (actionDate) => (
        <>
          {actionDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {actionDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: t('workspaceDetailsPage.workspaceSmartFunctionPanel.runNumber'),
      key: 'runCount',
      render: (text, record) => (
        <>
          {t('smartFunctionDetailsPage.runCount', {
            count: record.algorithmRunIndex || 0,
          })}
        </>
      ),
    },
    {
      title: t('Status'),
      dataIndex: 'statusText',
      key: 'statusText',
      render: (text: string) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, record) => {
        return (
          <>
            <SmartFunctionActionDropdown
              key={getRecordId(record.id)}
              algorithmRun={getRecord(record)}
            />
          </>
        )
      },
    },
  ]

  if (selectedWorkspace?.typeCode === workspaceTypes.OFR_QR) {
    columns.splice(1, 0, {
      title: t('workspaceDetailsPage.workspaceSmartFunctionPanel.template'),
      dataIndex: 'config',
      key: 'config',
      width: '15%',
      render: (config: QualityReviewConfigValues) => {
        const fileName = config?.documentTemplateName
        return (
          <>
            {fileName ? (
              <Tag
                icon={<FileTextOutlined />}
                color="default"
                style={{
                  textOverflow: 'ellipsis',
                }}
              >
                {fileName}
              </Tag>
            ) : (
              NO_DATA_PLACEHOLDER
            )}
          </>
        )
      },
    })
  }

  const handleRecordClick = (record: ExtendedAlgorithmRun) => {
    if (record.id !== '0') {
      dispatch(clearSelectedAlgorithmRun())
      let baseRoute = ''
      let extra = ''
      switch (selectedWorkspace?.typeCode) {
        case workspaceTypes.BIOMARK:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK
          break
        case workspaceTypes.REFAI:
          baseRoute = singleWorkspace
            ? routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
            : routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
          break
        case workspaceTypes.REGSUB:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB
          break
        case workspaceTypes.DEL:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL
          break
        case workspaceTypes.OFR_QR:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR
          break
        case workspaceTypes.RISK_REL:
          switch (record.id) {
            case '1':
              baseRoute =
                routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION
              extra = `/${deviationId}`
              break
            case '2':
              baseRoute =
                routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_ROOT_CAUSE_DISCOVERY
              extra = `/${deviationId}`
              break
            case '3':
              baseRoute =
                routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_CAPA_GENERATION
              extra = `/${deviationId}`
              break
          }
      }

      navigate(
        `${baseRoute}/${selectedWorkspace?.id}/${getRecordId(
          record.id
        )}${extra}`
      )
    }
  }

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <ListPanelLayout>
      <Table
        rowClassName={(record) =>
          record.id !== '0' &&
          record.runStatus === algorithmRunStatuses.COMPLETE
            ? 'page-list-table-row clickable'
            : 'page-list-table-row'
        }
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleRecordClick(record)
            },
          }
        }}
        scroll={{ y: 'calc(100vh - 155px)' }}
      />
    </ListPanelLayout>
  )
}

export default WorkspaceSmartFunctionPanel
