import {
  AimOutlined,
  BugOutlined,
  CloseCircleOutlined,
  FileSearchOutlined,
  LinkOutlined,
  MinusOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { Button, Table, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import Moment from 'react-moment'
import styled from 'styled-components'
import {
  BadgeColors,
  dateFormat,
  searchReviewSearchQueryResultItemStatus,
  searchReviewSearchQueryResultItemStatusColorKeys,
  searchReviewSearchQueryResultItemStatusTextMap,
} from '../constants/constants'
import { SearchTableRow } from './SearchTable'
import SimpleSwitch from './SimpleSwitch'
import Badge from './display/Badge'
import SimpleSwitchContainer from './display/SimpleSwitchContainer'
import TableBoldColumnContainer from './display/TableBoldColumnContainer'
const { Text, Paragraph } = Typography

const FirstLineContainer = styled.div`
  margin: 0 8px 4px 8px;
`

const SecondLineContainer = styled.div`
  font-size: 12px;
  margin: 0 8px 0 8px;
`

const LanguageContainer = styled.div`
  text-transform: capitalize;
`

const ExpandedSection = styled.div`
  padding: 24px;
  background: var(--color-bg-layout);
`

const ExpandIconWrapper = styled.div`
  .ant-table-row-expand-icon {
    background: var(--color-bg-container);
    border-radius: 4px;
    border: 1px solid var(--color-border);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: var(--color-hover-bg);
      border-color: var(--color-primary);
    }

    &.ant-table-row-expand-icon-expanded,
    &.ant-table-row-expand-icon-collapsed {
      border-color: var(--color-primary);
      color: var(--color-primary);
    }
  }
`

const ExpandIconButton = styled(Button)`
  margin-right: 8px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`

const TableWrapper = styled(ExpandIconWrapper)`
  .ant-table-expanded-row {
    .ant-table-cell {
      background: var(--color-bg-layout) !important;
      padding: 0;
    }
  }
  .ant-table-expanded-row > .ant-table-cell {
    border-bottom: none;
  }

  .ant-table-tbody > tr.ant-table-expanded-row:hover > td {
    background: var(--color-bg-layout);
  }
`

const ExpandedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  padding: 16px 24px;
`

const ExpandedColumn = styled.div`
  min-width: 0;
`

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`

interface HistoricalReviewSearchTableProps {
  formattedData: SearchTableRow[]
  loadingIds?: string[]
  onClick?: (recordId: string) => void
  maxHeight?: string
  onSummarize?: (recordId: string) => void
  onSwitchClick?: (recordId: string) => void
}

const HistoricalReviewSearchTable = ({
  formattedData,
  loadingIds = [],
  onClick,
  maxHeight,
  onSummarize,
  onSwitchClick,
}: HistoricalReviewSearchTableProps) => {
  const expandedRowRender = (record: SearchTableRow) => {
    let rootCauseBadges: string[] = []
    if (record.tagDetails) {
      rootCauseBadges = Object.keys(record.tagDetails)
    }

    return (
      <ExpandedSection>
        <ExpandedGrid>
          <ExpandedColumn>
            <Text type="secondary">
              {t(
                'searchReviewDetailsPage.historicalReviewSearchTable.rootCause'
              )}
            </Text>
            <TagsContainer>
              {rootCauseBadges?.length > 0 ? (
                rootCauseBadges.map((badge, badgeIndex) => (
                  <Badge
                    key={badgeIndex}
                    color={BadgeColors.ROOT_CAUSE_DISCOVERY}
                    icon={<BugOutlined />}
                  >
                    {badge}
                  </Badge>
                ))
              ) : (
                <Paragraph style={{ marginBottom: 12 }}>
                  {t(
                    'searchReviewDetailsPage.historicalReviewSearchTable.noRootCause'
                  )}
                </Paragraph>
              )}
            </TagsContainer>
          </ExpandedColumn>
          <ExpandedColumn>
            <Text type="secondary">
              {t(
                'searchReviewDetailsPage.historicalReviewSearchTable.deviationSummary'
              )}
            </Text>
            <Paragraph style={{ marginBottom: 12 }}>
              {record?.summary ||
                t(
                  'searchReviewDetailsPage.historicalReviewSearchTable.noSummaryGenerated'
                )}
            </Paragraph>
          </ExpandedColumn>
          <ExpandedColumn>
            <Text type="secondary">
              {t(
                'searchReviewDetailsPage.historicalReviewSearchTable.capaSummary'
              )}
            </Text>
            <Paragraph style={{ marginBottom: 12 }}>
              {t(
                'searchReviewDetailsPage.historicalReviewSearchTable.noSummaryGenerated'
              )}
            </Paragraph>
          </ExpandedColumn>
        </ExpandedGrid>
      </ExpandedSection>
    )
  }

  const searchReviewSearchQueryResultItemStatusIcons = {
    [searchReviewSearchQueryResultItemStatus.PROCESSING]: <SyncOutlined spin />,
    [searchReviewSearchQueryResultItemStatus.READY]: <AimOutlined />,
    [searchReviewSearchQueryResultItemStatus.FAILED]: <CloseCircleOutlined />,
    [searchReviewSearchQueryResultItemStatus.INGESTION]: <SyncOutlined spin />,
  }

  const renderActionButtons = (record: SearchTableRow) => {
    const isSummarizeDisabled =
      loadingIds?.length > 0 ||
      record.summaryStatus ===
        searchReviewSearchQueryResultItemStatus.PROCESSING ||
      record.summaryStatus === searchReviewSearchQueryResultItemStatus.INGESTION

    return (
      <div data-clickable>
        <Button
          type="link"
          color="default"
          icon={<LinkOutlined />}
          href={record.documentLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        />
        <Tooltip
          title={t('searchTable.summarizeTooltip')}
          placement="left"
          align={{ offset: [-24, 0] }}
        >
          <Button
            type="default"
            icon={<FileSearchOutlined />}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              event.stopPropagation()
              onSummarize?.(record.id)
            }}
            loading={loadingIds?.includes(record.id)}
            disabled={isSummarizeDisabled}
          />
        </Tooltip>
      </div>
    )
  }

  const columns: ColumnsType<SearchTableRow> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '30%',
      render: (_: string, record: SearchTableRow) => (
        <TableBoldColumnContainer>{record.title}</TableBoldColumnContainer>
      ),
    },
    {
      title: t('Authors'),
      dataIndex: 'authors',
      key: 'authors',
      width: '30%',
      render: (_: string, record: SearchTableRow) => (
        <>
          <FirstLineContainer>
            {record.publicationName || t('searchTable.noJournalAvailable')}
          </FirstLineContainer>
          <SecondLineContainer>
            {record.authors?.join(', ') || t('searchTable.noAuthorAvailable')}
          </SecondLineContainer>
        </>
      ),
    },
    {
      title: t('Date'),
      dataIndex: 'publicationDate',
      key: 'publicationDate',
      width: '20%',
      render: (_: string, record: SearchTableRow) => (
        <>
          <FirstLineContainer>
            {record.publicationDate ? (
              <Moment
                date={record.publicationDate}
                format={dateFormat.PRIMARY}
              />
            ) : (
              t('searchTable.noDateAvailable')
            )}
          </FirstLineContainer>
          <SecondLineContainer>
            <LanguageContainer>
              {record.language || t('searchTable.noLanguageAvailable')}
            </LanguageContainer>
          </SecondLineContainer>
        </>
      ),
    },
    {
      title: t('searchTable.summaryStatus'),
      dataIndex: 'summaryStatus',
      key: 'summaryStatus',
      width: '15%',
      render: (_: string, record: SearchTableRow) => {
        const summaryStatus = record.summaryStatus || ''
        const tagText =
          summaryStatus in searchReviewSearchQueryResultItemStatusTextMap
            ? searchReviewSearchQueryResultItemStatusTextMap[summaryStatus]
            : ''
        return (
          <>
            {tagText && (
              <Badge
                color={
                  searchReviewSearchQueryResultItemStatusColorKeys[
                    summaryStatus
                  ] || BadgeColors.DEFAULT
                }
                icon={
                  searchReviewSearchQueryResultItemStatusIcons[summaryStatus]
                }
              >
                {tagText}
              </Badge>
            )}
          </>
        )
      },
    },

    {
      title: (
        <>
          {t('searchTable.include')}/<br />
          {t('searchTable.exclude')}
        </>
      ),
      dataIndex: 'includeExclude',
      key: 'includeExclude',
      width: '15%',
      render: (_: string, record: SearchTableRow) => (
        <SimpleSwitchContainer>
          <SimpleSwitch
            data-testid={`simple-switch-${record.id}`}
            checked={record.included}
            onClick={() => {
              onSwitchClick?.(record.id)
            }}
            loading={loadingIds?.includes(record.id)}
            disabled={loadingIds && loadingIds?.length > 0}
          />
          <div data-testid={`simple-switch-text-${record.id}`}>
            {record.included ? t('Included') : t('Excluded')}
          </div>
        </SimpleSwitchContainer>
      ),
    },
    {
      title: t('Actions'),
      key: 'action',
      dataIndex: 'action',
      align: 'right',
      width: '10%',
      render: (_: string, record: SearchTableRow) =>
        renderActionButtons(record),
    },
  ]

  const handleRowClick = (e: React.MouseEvent, record: SearchTableRow) => {
    const target = e.target as HTMLElement
    const isClickable = target.closest('[data-clickable]')

    if (!isClickable) {
      onClick?.(record.id)
    }
  }

  return (
    <TableWrapper>
      <Table
        rowClassName="page-list-table-row clickable"
        columns={columns}
        dataSource={formattedData}
        loading={false}
        pagination={false}
        scroll={{ y: maxHeight }}
        expandable={{
          expandedRowRender,
          rowExpandable: () => true,
          defaultExpandAllRows: false,
          expandRowByClick: false,
          showExpandColumn: true,
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandIconButton
              type={expanded ? 'primary' : 'default'}
              size="small"
              icon={expanded ? <MinusOutlined /> : <PlusOutlined />}
              onClick={(e) => {
                e.stopPropagation()
                onExpand(record, e)
              }}
            />
          ),
        }}
        onRow={(record) => ({
          onClick: (e) => handleRowClick(e, record),
        })}
      />
    </TableWrapper>
  )
}

export default HistoricalReviewSearchTable
