import { ControlOutlined, EyeOutlined } from '@ant-design/icons'
import { MenuProps } from 'antd'
import { workspaceTypes } from '../../../../../constants/constants'
import { smartFunctionActionService } from '../../../../../services'
import {
  AlgorithmRun,
  WorkspaceDetails,
  WorkspaceTypeCode,
} from '../../../../../store/reducers/workspaceReducer'
import { SmartFunctionActionDropdownKeys } from '../../../../../hooks/useSmartFunctionActions'
import {
  BiomarkConfigurationModal,
  BiomarkLastConfigurationModal,
} from '../../../BiomarkSmartFunctionDetailsPage/modals'
import {
  DelConfigurationModal,
  DelLastConfigurationModal,
} from '../../../DelSmartFunctionDetailsPage/modals'
import {
  RegulatorySubmissionConfigurationModal,
  RegulatorySubmissionLastConfigurationModal,
} from '../../../RegulatorySubmissionFunctionDetailsPage/modals'

export const getWorkspaceTypeItems = (
  typeCode?: WorkspaceTypeCode,
  algorithmRun?: AlgorithmRun,
  selectedWorkspace?: WorkspaceDetails
): MenuProps['items'] => {
  if (!algorithmRun || !selectedWorkspace) return []

  switch (typeCode) {
    case workspaceTypes.BIOMARK: {
      const config = smartFunctionActionService.getBiomarkConfig(
        algorithmRun,
        selectedWorkspace
      )
      return [
        {
          key: SmartFunctionActionDropdownKeys.BIOMARK_CONFIGURATION,
          icon: <ControlOutlined />,
          label: <BiomarkConfigurationModal config={config} />,
        },
        {
          key: SmartFunctionActionDropdownKeys.VIEW_BIOMARK_RUN_SETUP,
          icon: <EyeOutlined />,
          label: <BiomarkLastConfigurationModal config={config} />,
        },
      ]
    }
    case workspaceTypes.REGSUB: {
      const config = smartFunctionActionService.getRegsubConfig(algorithmRun)
      return [
        {
          key: SmartFunctionActionDropdownKeys.REGSUB_CONFIGURATION,
          icon: <ControlOutlined />,
          label: <RegulatorySubmissionConfigurationModal config={config} />,
        },
        {
          key: SmartFunctionActionDropdownKeys.VIEW_REGSUB_RUN_SETUP,
          icon: <EyeOutlined />,
          label: <RegulatorySubmissionLastConfigurationModal config={config} />,
        },
      ]
    }
    case workspaceTypes.DEL: {
      const config = smartFunctionActionService.getDelConfig(algorithmRun)
      return [
        {
          key: SmartFunctionActionDropdownKeys.DEL_CONFIGURATION,
          icon: <ControlOutlined />,
          label: <DelConfigurationModal config={config} />,
        },
        {
          key: SmartFunctionActionDropdownKeys.VIEW_DEL_RUN_SETUP,
          icon: <EyeOutlined />,
          label: <DelLastConfigurationModal config={config} />,
        },
      ]
    }
    default:
      return []
  }
}
