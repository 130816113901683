import { ReadOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { t } from 'i18next'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ModalBase,
  ModalContentContainer,
  ModalStepsTitle,
} from '../../../../components'
import { useSearchReviews } from '../../../../hooks'

import styled from 'styled-components'
import {
  ModalSteps,
  SearchReviewSavingType,
  searchReviewSavingTypes,
  SearchReviewType,
  searchReviewTypes,
} from '../../../../constants/constants'
import notificationService from '../../../../services/notificationService'
import { RootState } from '../../../../store'
import { DataSource } from '../../../../store/reducers/dataSourceReducer'
import { SearchReview } from '../../../../store/reducers/searchReviewReducer'
import { SearchMethod } from '../../../../types/generalTypes'
import { routePaths } from '../../../RootPage'
import {
  searchReviewPanelTypes,
  searchReviewTabs,
} from '../../../SearchReviewDetailsPage/SearchReviewDetailsPage'
import CreateSearchReviewModalFooter from './footer/CreateSearchReviewFooter'
import CreateSearchReviewDefinitionStep from './steps/CreateSearchReviewDefinitionStep'
import CreateSearchReviewFinalStep from './steps/CreateSearchReviewFinalStep'
import CreateSearchReviewSetupStep from './steps/CreateSearchReviewSetupStep'
import CreateSearchReviewTypeStep from './steps/CreateSearchReviewTypeStep'

export interface CreateSearchReviewModalProps {
  query?: string
  searchMethod?: SearchMethod
  sourceId?: string
  period?: string
  total?: number
  disabled?: boolean
}

const StyledModalBase = styled(ModalBase)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 100px);
  }

  .ant-modal-body {
    flex: 1;
    overflow-y: auto;
    min-height: 0;
  }

  .ant-modal-footer {
    flex-shrink: 0;
  }
`

const CreateSearchReviewModal = ({
  query,
  sourceId,
  period,
  total,
  searchMethod,
  disabled,
}: CreateSearchReviewModalProps) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const [confirmLoading, setConfirmLoading] = useState(false)

  const [reviewTitle, setReviewTitle] = useState('')
  const [searchReview, setSearchReview] = useState<SearchReview | undefined>(
    undefined
  )

  const source: DataSource | undefined = useSelector((state: RootState) =>
    state.dataSource.dataSourceList.find((source) => source.id === sourceId)
  )

  const { createSearchReview, addToExistingSearchReview } = useSearchReviews({
    preventFetch: true,
  })

  const defaultValues = useMemo(
    () => ({
      reviewTitle: '',
      searchReviewId: '',
      reviewType: searchReviewTypes.LITERATURE_REVIEW,
      savingType: searchReviewSavingTypes.NEW,
    }),
    []
  )

  const handleSubmit = useCallback(async () => {
    setConfirmLoading(true)
    const savingType = form.getFieldValue('savingType') as
      | SearchReviewSavingType
      | undefined
    if (!savingType) {
      return
    }

    const reviewType = form.getFieldValue('reviewType') as SearchReviewType

    const handleSuccess = (response: SearchReview, tab: string) => {
      setConfirmLoading(false)
      setOpen(false)
      navigate(
        `${routePaths.SEARCH_REVIEW_DETAILS}/${response.id}/${searchReviewPanelTypes.OVERVIEW}/${tab}`
      )
      notificationService.notificationSuccess(
        t(
          savingType === searchReviewSavingTypes.NEW
            ? 'searchPage.createSearchReviewModal.sucessCreateLiteratureReview'
            : 'searchPage.createSearchReviewModal.successAddSearchQueryToLiteratureReview'
        )
      )
    }

    const handleError = (error: any) => {
      console.error('axios fetch error', error)
      setOpen(false)
      setConfirmLoading(false)
    }

    try {
      if (savingType === searchReviewSavingTypes.NEW) {
        const response = await createSearchReview({
          query: query || '',
          searchMethod,
          sourceId: sourceId || '',
          period: period || '',
          reviewTitle,
          navigate,
          reviewType,
        })
        handleSuccess(response, searchReviewTabs.INFO)
      } else if (savingType === searchReviewSavingTypes.EXISTING) {
        const response = await addToExistingSearchReview({
          searchReviewId: searchReview?.id || '',
          query: query || '',
          searchMethod,
          sourceId: sourceId || '',
          period: period || '',
          navigate,
        })
        handleSuccess(response, searchReviewTabs.QUERIES)
      }
    } catch (error) {
      handleError(error)
    }
  }, [
    addToExistingSearchReview,
    createSearchReview,
    form,
    navigate,
    period,
    query,
    reviewTitle,
    searchMethod,
    searchReview,
    sourceId,
  ])

  const handleLiteratureReview = (value?: SearchReview) => {
    setSearchReview(value)
  }

  const resetValues = useCallback(() => {
    form.resetFields()
    setCurrentStep(0)
  }, [form])

  const handleCancel = useCallback(() => {
    resetValues()
    setOpen(false)
  }, [resetValues])

  const handleNextClick = useCallback(() => {
    form
      .validateFields()
      .then(() => setCurrentStep((currentStep) => currentStep + 1))
      .catch(() => {
        notificationService.notificationError(
          t('createWorkspaceModal.invalidFields')
        )
      })
  }, [form])

  const stepItems: ModalSteps[] = [
    {
      title: t('createSearchReviewModal.steps.typeStep.title'),
    },
    {
      title: t('createSearchReviewModal.steps.setupStep.title'),
    },
    {
      title: t('createSearchReviewModal.steps.definitionStep.title'),
    },
    {
      title: t('createSearchReviewModal.steps.reviewStep.title'),
    },
  ]

  const steps: ReactNode[] = useMemo(
    () => [
      <CreateSearchReviewTypeStep form={form} />,
      <CreateSearchReviewSetupStep form={form} />,
      <CreateSearchReviewDefinitionStep
        form={form}
        handleLiteratureReview={handleLiteratureReview}
        setReviewTitle={setReviewTitle}
        reviewTitle={reviewTitle}
      />,
      <CreateSearchReviewFinalStep
        form={form}
        query={query || ''}
        source={source}
        period={period || ''}
        total={total || 0}
      />,
    ],
    [form, period, query, reviewTitle, source, total]
  )

  const handlePreviousClick = useCallback(() => {
    if (currentStep === 2) {
      setReviewTitle('')
      form.setFieldValue('title', '')
    }
    setCurrentStep((currentStep) => currentStep - 1)
  }, [currentStep, form])

  const footers = useMemo(
    () => [
      <CreateSearchReviewModalFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
      />,
      <CreateSearchReviewModalFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
      <CreateSearchReviewModalFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
      <CreateSearchReviewModalFooter
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
        form={form}
      />,
    ],
    [form, handleCancel, handleNextClick, handlePreviousClick, handleSubmit]
  )

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={disabled}
        icon={<ReadOutlined />}
      >
        {t('searchPage.createSearchReviewModal.openModalButton')}
      </Button>
      <StyledModalBase
        destroyOnClose
        title={
          <ModalStepsTitle
            title={t('searchPage.createSearchReviewModal.title')}
            currentStep={currentStep}
            stepItems={stepItems}
          />
        }
        open={open}
        onOk={handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={footers[currentStep]}
        closable
      >
        <ModalContentContainer>
          <Form form={form} layout="vertical" initialValues={defaultValues}>
            {steps[currentStep]}
          </Form>
        </ModalContentContainer>
      </StyledModalBase>
    </>
  )
}

export default CreateSearchReviewModal
