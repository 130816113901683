import { DetailsPageLayout } from '../../../components'
import RiskReliefDeviationClassificationSmartFunctionDetailsPageHeader from './components/RiskReliefDeviationClassificationSmartFunctionDetailsPageHeader'
import RiskReliefDeviationClassificationSmartFunctionPanel from './panels/RiskReliefDeviationClassificationSmartFunctionPanel'

const RiskReliefDeviationClassificationSmartFunctionDetailsPage = () => {
  return (
    <DetailsPageLayout>
      <RiskReliefDeviationClassificationSmartFunctionDetailsPageHeader />
      <RiskReliefDeviationClassificationSmartFunctionPanel />
    </DetailsPageLayout>
  )
}

export default RiskReliefDeviationClassificationSmartFunctionDetailsPage
