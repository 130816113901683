import { t } from 'i18next'
import { useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '.'
import { workspaceTypes } from '../constants/constants'
import { routePaths } from '../pages/RootPage'
import { smartFunctionActionService } from '../services'
import notificationService from '../services/notificationService'
import { AlgorithmRun } from '../store/reducers/workspaceReducer'

interface UseSmartFunctionActionsProps {
  workspaceId?: string
  algorithmRun?: AlgorithmRun
  algorithmRunId?: string
}

export enum SmartFunctionActionDropdownKeys {
  RUN_ALGORITHM = 'RUN_ALGORITHM',
  CANCEL_RUN = 'CANCEL_RUN',
  BIOMARK_CONFIGURATION = 'BIOMARK_CONFIGURATION',
  REGSUB_CONFIGURATION = 'REGSUB_CONFIGURATION',
  DEL_CONFIGURATION = 'DEL_CONFIGURATION',
  VIEW_BIOMARK_RUN_SETUP = 'VIEW_BIOMARK_RUN_SETUP',
  VIEW_REGSUB_RUN_SETUP = 'VIEW_REGSUB_RUN_SETUP',
  VIEW_DEL_RUN_SETUP = 'VIEW_DEL_RUN_SETUP',
  EXPORT_RUN = 'EXPORT_RUN',
  PRINT_CONTENT = 'PRINT_CONTENT',
  RISK_RELIEF_CONFIGURATION = 'RISK_RELIEF_CONFIGURATION',
}

export interface MenuItemHandlers {
  handleAlgorithmRunClick: () => void
  handleCancelRunClick: () => void
  handleExportRun: () => void
  handlePrintContent: () => void
}

export const useSmartFunctionActions = ({
  workspaceId,
  algorithmRun,
  algorithmRunId,
}: UseSmartFunctionActionsProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { selectedWorkspace, refreshWorkspaceDetails, exportSmartFunctionCsv } =
    useWorkspaceDetails({
      workspaceId,
      preventFetch: true,
    })

  const { refreshAlgorithmRun, runAlgorithm, cancelAlgorithmRun } =
    useAlgorithmRunDetails({
      algorithmRunId,
      workspaceId,
      preventFetch: true,
    })

  const { deviationId } = useParams()

  const getBaseRoute = (typeCode?: string, pathname?: string) => {
    switch (typeCode) {
      case workspaceTypes.OFR_QR:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR
      case workspaceTypes.BIOMARK:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK
      case workspaceTypes.REGSUB:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB
      case workspaceTypes.DEL:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL
      case workspaceTypes.RISK_REL:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION
      case workspaceTypes.REFAI:
        return pathname?.includes(
          routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
        )
          ? routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
          : routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
      default:
        return ''
    }
  }

  const handleAlgorithmRunClick = useCallback(async () => {
    if (!algorithmRun || !selectedWorkspace) return

    try {
      const { typeCode, id } = selectedWorkspace
      const config = await smartFunctionActionService.getAlgorithmConfig(
        typeCode,
        selectedWorkspace,
        deviationId,
        algorithmRun
      )

      if (!config) return
      
      const response = await runAlgorithm(workspaceId || '', config)

      if (!location.pathname.includes(routePaths.WORKSPACE_DETAILS)) {
        const baseRoute = getBaseRoute(typeCode, location.pathname)
        navigate(`${baseRoute}/${id}/${response.id}`)
      } else {
        refreshWorkspaceDetails()
      }

      notificationService.notificationSuccess(
        t('smartFunctionDetailsPage.runStarted')
      )
    } catch (error) {
      console.error('Error running algorithm:', error)
      notificationService.notificationError(
        t('smartFunctionDetailsPage.runFailed')
      )
    }
  }, [algorithmRun, selectedWorkspace, deviationId, runAlgorithm, workspaceId, location.pathname, navigate, refreshWorkspaceDetails])


  const handleCancelRunClick = useCallback(async () => {
    try {
      await cancelAlgorithmRun(
        selectedWorkspace?.id || '',
        algorithmRun?.id || '',
        navigate
      )
      refreshWorkspaceDetails()
      refreshAlgorithmRun()
      notificationService.notificationWarning(
        t('smartFunctionDetailsPage.runCancelled')
      )
    } catch (error) {
      console.error('Error cancelling run:', error)
    }
  }, [
    selectedWorkspace,
    algorithmRun,
    navigate,
    refreshWorkspaceDetails,
    refreshAlgorithmRun,
    cancelAlgorithmRun,
  ])

  const handleExportRun = useCallback(() => {
    if (selectedWorkspace && algorithmRun) {
      exportSmartFunctionCsv(selectedWorkspace.id, algorithmRun.id)
    }
  }, [selectedWorkspace, algorithmRun, exportSmartFunctionCsv])

  const handlePrintContent = useCallback(() => {
    window.print()
  }, [])

  return {
    handleAlgorithmRunClick,
    handleCancelRunClick,
    handleExportRun,
    handlePrintContent,
  }
}

export default useSmartFunctionActions
