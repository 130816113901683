import {
  ApartmentOutlined,
  AuditOutlined,
  BulbOutlined,
  CloudDownloadOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  ExceptionOutlined,
  FileSearchOutlined,
  PartitionOutlined,
  ReadOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import type { Location } from '@remix-run/router'
import { BreadcrumbProps, ItemType } from 'antd/lib/breadcrumb/Breadcrumb'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import {
  useAlgorithmRunDetails,
  useDataSources,
  useDataStores,
  useOfrDocumentTemplate,
  useOfrDocumentTemplateDetails,
  useSearchReviews,
} from '.'
import { NO_DATA_PLACEHOLDER, pageTypes } from '../constants/constants'
import { routePaths } from '../pages/RootPage'
import {
  workSpaceTabs,
  workspacePanelTypes,
} from '../pages/WorkspaceDetailsPage/WorkspaceDetailsPage'
import { breadcrumbService, dataSourceService } from '../services'
import useUsers from './useUsers'
import useWorkspaceDetails from './useWorkspaceDetails'
import useWorkspaces from './useWorkspaces'

const getPageTypeDetails = (basePath: string) => {
  switch (basePath) {
    case routePaths.WORKSPACE_DETAILS:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR_DOCUMENT_DETAILS:
      return pageTypes.WORKSPACE
    case routePaths.SEARCH_DOCUMENT_DETAILS:
    case routePaths.SEARCH_DOCUMENT_SUMMARIES:
      return pageTypes.SEARCH
    case routePaths.SEARCH_REVIEW_DETAILS:
    case routePaths.SEARCH_REVIEW_SEARCH:
    case routePaths.SEARCH_REVIEW_DOCUMENT:
      return pageTypes.SEARCH_REVIEW
    case routePaths.USER_DETAILS:
      return pageTypes.USER
    case routePaths.DATA_SOURCE_LIST:
    case routePaths.DATA_SOURCE_DETAILS:
      return pageTypes.DATA_SOURCE
    case routePaths.DATA_STORE_LIST:
    case routePaths.DATA_STORE_DETAILS:
      return pageTypes.DATA_STORE
    case routePaths.OFR_DOCUMENT_TEMPLATE_LIST:
    case routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS:
      return pageTypes.OFR
    default:
      return pageTypes.OTHER
  }
}

const getPageTypeIcons = (basepath: string) => {
  switch (basepath) {
    case routePaths.WORKSPACE_LIST:
    case routePaths.WORKSPACE_DETAILS:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR_DOCUMENT_DETAILS:
      return <ContainerOutlined />
    case routePaths.SEARCH:
    case routePaths.SEARCH_DOCUMENT_DETAILS:
      return <FileSearchOutlined />
    case routePaths.SEARCH_DOCUMENT_SUMMARIES:
      return <BulbOutlined />
    case routePaths.SEARCH_REVIEW_LIST:
    case routePaths.SEARCH_REVIEW_DETAILS:
    case routePaths.SEARCH_REVIEW_DOCUMENT:
    case routePaths.SEARCH_REVIEW_SEARCH:
      return <ReadOutlined />
    case routePaths.USER_LIST:
    case routePaths.USER_DETAILS:
      return <TeamOutlined />
    case routePaths.DATA_SOURCE_LIST:
    case routePaths.DATA_SOURCE_DETAILS:
      return <DatabaseOutlined />
    case routePaths.DATA_STORE_LIST:
    case routePaths.DATA_STORE_DETAILS:
      return <CloudDownloadOutlined />
    case routePaths.AUDIT_TRAIL_LIST:
      return <AuditOutlined />
    case routePaths.SETTINGS:
      return <SettingOutlined />
    case routePaths.OFR_DOCUMENT_TEMPLATE_LIST:
    case routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS:
      return <ExceptionOutlined />
    default:
      return ''
  }
}

const useBreadcrumbItems = () => {
  const location: Location = useLocation()
  const basePath = `/${location.pathname.split('/')[1]}`
  const pageTypeDetails = useMemo(
    () => getPageTypeDetails(basePath),
    [basePath]
  )
  const pageIcon = getPageTypeIcons(basePath)

  const { workspaceList } = useWorkspaces({
    preventFetch: pageTypeDetails !== pageTypes.WORKSPACE,
  })
  const { selectedWorkspace } = useWorkspaceDetails({
    preventFetch: true,
  })

  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    preventFetch: true,
  })

  const { searchReviewList, selectedSearchReview, selectedSearchQuery } =
    useSearchReviews({
      preventFetch: pageTypeDetails !== pageTypes.SEARCH_REVIEW,
    })
  const { internalDataSourceList, selectedDataSource } = useDataSources({
    preventFetch: basePath !== routePaths.DATA_SOURCE_DETAILS,
  })
  const { dataStoreList, selectedDataStore } = useDataStores({
    preventFetch: basePath !== routePaths.DATA_STORE_DETAILS,
  })
  const { userList, selectedUser } = useUsers({
    preventFetch: basePath !== routePaths.USER_DETAILS,
  })

  const { ofrDocumentTemplateList } = useOfrDocumentTemplate({
    preventFetch: basePath !== routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS,
  })

  const { selectedOfrDocumentTemplate } = useOfrDocumentTemplateDetails({
    preventFetch: true,
  })

  //////////////////////////////////////////////////
  // Workspace

  const workspaceItems = [
    breadcrumbService.getBreadcrumbItem(t('breadcrumb.workspace'), pageIcon),
  ]

  const workspaceDetailsItems: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.workspace'),
      pageIcon,
      routePaths.WORKSPACE_LIST
    ),
    breadcrumbService.getBreadcrumbItem(
      selectedWorkspace?.title || '',
      undefined,
      undefined,
      workspaceList && breadcrumbService.getWorkspaceMenuItems(workspaceList)
    ),
  ]

  // Helper function to safely retrieve a specific segment from path
  const getPathSegment = (
    pathname: string,
    index: number,
    fallback: string
  ) => {
    const segments = pathname.split('/')
    return segments[index] || fallback
  }

  const ofrDocumentDetailTitle = getPathSegment(
    location.pathname,
    5,
    NO_DATA_PLACEHOLDER
  )

  const isOfrDocumentDetailsPage = location.pathname.includes(
    routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR_DOCUMENT_DETAILS
  )

  const workspaceSmartFunctionItems: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.workspace'),
      pageIcon,
      routePaths.WORKSPACE_LIST
    ),
    breadcrumbService.getBreadcrumbItem(
      selectedWorkspace?.title || '',
      undefined,
      undefined,
      workspaceList && breadcrumbService.getWorkspaceMenuItems(workspaceList)
    ),
    breadcrumbService.getBreadcrumbItem(
      '',
      <ApartmentOutlined />,
      `${routePaths.WORKSPACE_DETAILS}/${selectedWorkspace?.id}/${workspacePanelTypes.OVERVIEW}/${workSpaceTabs.SMART_FUNCTION}`
    ),
    breadcrumbService.getBreadcrumbItem(
      isOfrDocumentDetailsPage
        ? ''
        : selectedWorkspace?.algorithmName || NO_DATA_PLACEHOLDER,
      <PartitionOutlined />,
      `${routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR}/${selectedWorkspace?.id}/${selectedAlgorithmRun?.id}`
    ),
    // Additional breadcrumb item for OFR QR document details
    ...(isOfrDocumentDetailsPage
      ? [
          breadcrumbService.getBreadcrumbItem(
            ofrDocumentDetailTitle,
            pageIcon,
            undefined
          ),
        ]
      : []),
  ]

  //////////////////////////////////////////////////
  // Search

  const searchItems = [
    breadcrumbService.getBreadcrumbItem(t('breadcrumb.search'), pageIcon),
  ]
  const searchSelectedDocumentItems = [
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.search'),
      pageIcon,
      routePaths.SEARCH
    ),
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.searchDocument'),
      pageIcon
    ),
  ]

  const searchSummaries = [
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.search'),
      <FileSearchOutlined />,
      routePaths.SEARCH
    ),
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.searchSummaries'),
      pageIcon
    ),
  ]

  //////////////////////////////////////////////////
  // Literature review

  const searchReviewBreadCrumbItem = breadcrumbService.getBreadcrumbItem(
    t('breadcrumb.searchReview'),
    pageIcon,
    routePaths.SEARCH_REVIEW_LIST
  )
  const selectedSearchReviewBreadCrumbItem =
    breadcrumbService.getBreadcrumbItem(
      selectedSearchReview?.title || '',
      undefined,
      `${routePaths.SEARCH_REVIEW_DETAILS}/${selectedSearchReview?.id}`,
      breadcrumbService.getSearchReviewMenuItems(searchReviewList)
    )

  const searchReviewListItems = [
    breadcrumbService.getBreadcrumbItem(t('breadcrumb.searchReview'), pageIcon),
  ]
  const searchReviewDetailsItems: BreadcrumbProps['items'] = [
    searchReviewBreadCrumbItem,
    breadcrumbService.getBreadcrumbItem(
      selectedSearchReview?.title || '',
      undefined,
      undefined,
      breadcrumbService.getSearchReviewMenuItems(searchReviewList)
    ),
  ]
  const searchReviewSearchItems: BreadcrumbProps['items'] = [
    searchReviewBreadCrumbItem,
    selectedSearchReviewBreadCrumbItem,
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.searchReviewSearch'),
      pageIcon
    ),
  ]
  const searchReviewSearchDocumentItems: BreadcrumbProps['items'] = [
    searchReviewBreadCrumbItem,
    selectedSearchReviewBreadCrumbItem,
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.searchReviewSearch'),
      pageIcon,
      `${routePaths.SEARCH_REVIEW_SEARCH}/${selectedSearchReview?.id}/${selectedSearchQuery?.searchEvent}`
    ),
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.searchReviewSearchDocument'),
      undefined,
      undefined,
      breadcrumbService.getSearchReviewSearchMenuItems(
        selectedSearchReview?.id,
        selectedSearchReview?.searchQueries
      )
    ),
  ]

  //////////////////////////////////////////////////
  // Data source

  const dataSourceListItem: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(t('breadcrumb.dataSource'), pageIcon),
  ]

  const dataSourceDetailsItem: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.dataSource'),
      pageIcon,
      routePaths.DATA_SOURCE_LIST
    ),
    breadcrumbService.getBreadcrumbItem(
      dataSourceService.getDataSourceTitle(selectedDataSource),
      undefined,
      undefined,
      internalDataSourceList &&
        breadcrumbService.getDataSourceMenuItems(internalDataSourceList)
    ),
  ]

  ///////////////////////////////////////////////////
  // Data store

  const dataStoreListItem: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(t('breadcrumb.dataStore'), pageIcon),
  ]

  const dataStoreDetailsItem: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.dataStore'),
      pageIcon,
      routePaths.DATA_STORE_LIST
    ),
    breadcrumbService.getBreadcrumbItem(
      selectedDataStore?.title || '',
      undefined,
      undefined,
      dataStoreList && breadcrumbService.getDataStoreMenuItems(dataStoreList)
    ),
  ]

  //////////////////////////////////////////////////
  // User

  const userListItems: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(t('breadcrumb.userList'), pageIcon),
  ]

  const userDetailsItems: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(
      t('User'),
      pageIcon,
      routePaths.USER_LIST
    ),
    breadcrumbService.getBreadcrumbItem(
      selectedUser?.displayName || '',
      undefined,
      undefined,
      userList && breadcrumbService.getUserMenuItems(userList)
    ),
  ]

  //////////////////////////////////////////////////
  // Audit trails

  const auditTrailListItems: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(t('breadcrumb.auditTrails'), pageIcon),
  ]

  //////////////////////////////////////////////////
  // Settings

  const settingsItems: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(t('breadcrumb.settings'), pageIcon),
  ]

  //////////////////////////////////////////////////
  // OFR
  const ofrDocumentTemplateListItems: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(t('breadcrumb.ofr'), pageIcon),
  ]

  const ofrDocumentTemplateDetailsItems: BreadcrumbProps['items'] = [
    breadcrumbService.getBreadcrumbItem(
      t('breadcrumb.ofr'),
      pageIcon,
      routePaths.OFR_DOCUMENT_TEMPLATE_LIST
    ),
    breadcrumbService.getBreadcrumbItem(
      selectedOfrDocumentTemplate?.title || '',
      undefined,
      undefined,
      ofrDocumentTemplateList &&
        breadcrumbService.getOfrDocumentTemplateMenuItems(
          ofrDocumentTemplateList
        )
    ),
  ]

  ////////////////////////////////////////////////////
  // Select the right breadcrumb items

  let breadcrumbItems: ItemType[] = []
  switch (basePath) {
    case routePaths.WORKSPACE_LIST:
      breadcrumbItems = workspaceItems
      break
    case routePaths.WORKSPACE_DETAILS:
      breadcrumbItems = workspaceDetailsItems
      break
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR_DOCUMENT_DETAILS:
    case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION:
      breadcrumbItems = workspaceSmartFunctionItems
      break
    case routePaths.SEARCH:
      breadcrumbItems = searchItems
      break
    case routePaths.SEARCH_DOCUMENT_DETAILS:
      breadcrumbItems = searchSelectedDocumentItems
      break
    case routePaths.SEARCH_DOCUMENT_SUMMARIES:
      breadcrumbItems = searchSummaries
      break
    case routePaths.SEARCH_REVIEW_LIST:
      breadcrumbItems = searchReviewListItems
      break
    case routePaths.SEARCH_REVIEW_DETAILS:
      breadcrumbItems = searchReviewDetailsItems
      break
    case routePaths.SEARCH_REVIEW_SEARCH:
      breadcrumbItems = searchReviewSearchItems
      break
    case routePaths.SEARCH_REVIEW_DOCUMENT:
      breadcrumbItems = searchReviewSearchDocumentItems
      break
    case routePaths.USER_LIST:
      breadcrumbItems = userListItems
      break
    case routePaths.USER_DETAILS:
      breadcrumbItems = userDetailsItems
      break
    case routePaths.DATA_SOURCE_LIST:
      breadcrumbItems = dataSourceListItem
      break
    case routePaths.DATA_SOURCE_DETAILS:
      breadcrumbItems = dataSourceDetailsItem
      break
    case routePaths.DATA_STORE_LIST:
      breadcrumbItems = dataStoreListItem
      break
    case routePaths.DATA_STORE_DETAILS:
      breadcrumbItems = dataStoreDetailsItem
      break
    case routePaths.AUDIT_TRAIL_LIST:
      breadcrumbItems = auditTrailListItems
      break
    case routePaths.OFR_DOCUMENT_TEMPLATE_LIST:
      breadcrumbItems = ofrDocumentTemplateListItems
      break
    case routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS:
      breadcrumbItems = ofrDocumentTemplateDetailsItems
      break
    case routePaths.SETTINGS:
      breadcrumbItems = settingsItems
      break
  }

  return breadcrumbItems
}

export default useBreadcrumbItems
