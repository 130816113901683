import { t } from 'i18next'
import { ReactNode } from 'react'
import Moment from 'react-moment'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import BackButton from '../../../components/BackButton'
import BoldText from '../../../components/display/BoldText'
import { NO_DATA_PLACEHOLDER, dateFormat } from '../../../constants/constants'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../hooks'
import SmartFunctionActionDropdown from './SmartFunctionActionDropdown/SmartFunctionActionDropdown'
import SmartFunctionSelectRun from './SmartFunctionSelectRun'

export const SmartFunctionSubHeader = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 12px;
  gap: 16px;
`

export const SmartFunctionMainSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const SmartFunctionSubHeaderLine = styled.div`
  display: flex;
  flex: 1;
  padding: 0 8px;
  gap: 16px;
  color: ${(props) => props.theme.token.colorText};
`

export const SmartFunctionSubHeaderCell = styled.div`
  flex: 50%;
  display: flex;
  align-items: center;
`

export const SmartFunctionSubHeaderCellContainer = styled.div`
  flex: 50%;
  display: flex;
`

export const SmartFunctionSubHeaderCellSection = styled.div`
  align-content: center;
  flex: auto;
  color: ${(props) => props.theme.token.colorText};
`

const SmartFunctionHeaderFirstRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
`

interface RiskReliefSmartFunctionHeaderLayoutProps {
  backButtonUrl: string
  children?: ReactNode
  onRunChange?: (value?: string) => void
}

const RiskReliefSmartFunctionHeader = ({
  backButtonUrl,
  children,
  onRunChange,
}: RiskReliefSmartFunctionHeaderLayoutProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
    preventFetch: true,
  })
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  return (
    <SmartFunctionSubHeader>
      <BackButton
        url={backButtonUrl}
        tooltipMessage={t('smartFunctionDetailsPage.backButtonTooltip')}
      />
      <SmartFunctionMainSubHeader>
        <SmartFunctionHeaderFirstRow>
          <SmartFunctionSubHeaderLine>
            <SmartFunctionSubHeaderCell>
              <BoldText>
                {selectedWorkspace?.algorithmName || NO_DATA_PLACEHOLDER}
              </BoldText>
            </SmartFunctionSubHeaderCell>
            <SmartFunctionSubHeaderCellContainer>
              <SmartFunctionSubHeaderCellSection>
                {selectedAlgorithmRun?.actionDate ? (
                  <Moment
                    local
                    date={selectedAlgorithmRun?.actionDate}
                    format={dateFormat.PRIMARY}
                  />
                ) : (
                  NO_DATA_PLACEHOLDER
                )}
              </SmartFunctionSubHeaderCellSection>
              <SmartFunctionSubHeaderCellSection>
                <SmartFunctionSelectRun onChange={onRunChange} />
              </SmartFunctionSubHeaderCellSection>
              <SmartFunctionSubHeaderCellSection>
                {selectedAlgorithmRun?.runStatus
                  ? t(
                      `workspaceDetailsPage.status.${selectedAlgorithmRun?.runStatus}`
                    )
                  : NO_DATA_PLACEHOLDER}
              </SmartFunctionSubHeaderCellSection>
            </SmartFunctionSubHeaderCellContainer>
          </SmartFunctionSubHeaderLine>
          <SmartFunctionActionDropdown
            key={selectedAlgorithmRun?.id}
            algorithmRun={selectedAlgorithmRun}
          />
        </SmartFunctionHeaderFirstRow>
        <SmartFunctionSubHeaderLine>{children}</SmartFunctionSubHeaderLine>
      </SmartFunctionMainSubHeader>
    </SmartFunctionSubHeader>
  )
}

export default RiskReliefSmartFunctionHeader
