import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { algorithmRunStatuses } from '../../../../constants/constants'
import {
  useAlgorithmRunDetails,
  useSmartFunctionActions,
  useWorkspaceDetails,
} from '../../../../hooks'
import { SmartFunctionActionDropdownKeys } from '../../../../hooks/useSmartFunctionActions'
import { AlgorithmRun } from '../../../../store/reducers/workspaceReducer'
import { getCommonMenuItems } from './menuItems/SmartFunctionActionDropdownCommonMenuItems'
import { getWorkspaceTypeItems } from './menuItems/SmartFunctionActionDropdownWorkspaceTypeItems'

interface SmartFunctionActionDropdownProps {
  algorithmRun?: AlgorithmRun
}

const SmartFunctionActionDropdown = ({
  algorithmRun,
}: SmartFunctionActionDropdownProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const [fetchDone, setFetchDone] = useState(false)
  const [selectedAlgorithmRunId, setSelectedAlgorithmRunId] = useState<
    string | undefined
  >(algorithmRunId)

  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const { refreshAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId: selectedAlgorithmRunId,
    workspaceId,
    preventFetch: true,
  })

  useEffect(() => {
    if (algorithmRunId && algorithmRunId !== selectedAlgorithmRunId) {
      setSelectedAlgorithmRunId(algorithmRunId)
      setFetchDone(false)
    }
  }, [algorithmRunId, selectedAlgorithmRunId])

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAlgorithmRunId && !fetchDone) {
        await refreshWorkspaceDetails()
        await refreshAlgorithmRun()
        setFetchDone(true)
      }
    }
    fetchData()
  }, [
    fetchDone,
    refreshAlgorithmRun,
    refreshWorkspaceDetails,
    selectedAlgorithmRunId,
  ])

  const {
    handleAlgorithmRunClick,
    handleCancelRunClick,
    handleExportRun,
    handlePrintContent,
  } = useSmartFunctionActions({
    workspaceId,
    algorithmRun,
    algorithmRunId,
  })

  const handlers = {
    [SmartFunctionActionDropdownKeys.RUN_ALGORITHM]: handleAlgorithmRunClick,
    [SmartFunctionActionDropdownKeys.CANCEL_RUN]: handleCancelRunClick,
    [SmartFunctionActionDropdownKeys.EXPORT_RUN]: handleExportRun,
    [SmartFunctionActionDropdownKeys.PRINT_CONTENT]: handlePrintContent,
  }
  const isRunsAvailable = useMemo(() => {
    return (
      !algorithmRun?.runStatus ||
      algorithmRun.runStatus === algorithmRunStatuses.COMPLETE ||
      algorithmRun.runStatus === algorithmRunStatuses.FAILED ||
      algorithmRun.runStatus === algorithmRunStatuses.READY ||
      algorithmRun.runStatus === algorithmRunStatuses.CANCELLED
    )
  }, [algorithmRun])

  const isStatusOngoing = useMemo(
    () =>
      algorithmRun?.runStatus === algorithmRunStatuses.ONGOING_AI ||
      algorithmRun?.runStatus === algorithmRunStatuses.ONGOING_INGESTION,
    [algorithmRun]
  )

  const items = useMemo(
    () => [
      ...(getCommonMenuItems(
        isRunsAvailable,
        isStatusOngoing,
        algorithmRunId,
        algorithmRun,
        selectedWorkspace
      ) || []),
      ...(getWorkspaceTypeItems(
        selectedWorkspace?.typeCode,
        algorithmRun,
        selectedWorkspace
      ) || []),
    ],
    [
      isRunsAvailable,
      isStatusOngoing,
      algorithmRunId,
      algorithmRun,
      selectedWorkspace,
    ]
  )

  return (
    <Dropdown
      menu={{
        items,
        onClick: ({ key, domEvent }) => {
          domEvent.stopPropagation()
          const handlerKey = key as keyof typeof handlers
          handlers[handlerKey]?.()
        },
      }}
      placement="bottomRight"
      trigger={['hover']}
    >
      <Button icon={<MoreOutlined />} onClick={(e) => e.stopPropagation()} />
    </Dropdown>
  )
}

export default SmartFunctionActionDropdown
