import {
  ExportOutlined,
  PlaySquareOutlined,
  PrinterOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { MenuProps } from 'antd'
import { t } from 'i18next'
import {
  algorithmRunStatuses,
  workspaceTypes,
} from '../../../../../constants/constants'

import { SmartFunctionActionDropdownKeys } from '../../../../../hooks/useSmartFunctionActions'
import {
  AlgorithmRun,
  WorkspaceDetails,
} from '../../../../../store/reducers/workspaceReducer'

export const getCommonMenuItems = (
  isRunsAvailable: boolean,
  isStatusOngoing: boolean,
  algorithmRunId: string | undefined,
  algorithmRun?: AlgorithmRun,
  selectedWorkspace?: WorkspaceDetails
): MenuProps['items'] => [
  {
    key: SmartFunctionActionDropdownKeys.RUN_ALGORITHM,
    icon: <PlaySquareOutlined />,
    disabled: !isRunsAvailable,
    label: t(
      'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.runAlgorithm'
    ),
  },
  {
    key: SmartFunctionActionDropdownKeys.CANCEL_RUN,
    icon: <StopOutlined />,
    disabled: !isStatusOngoing,
    label: t(
      'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.cancelRun'
    ),
  },
  ...(algorithmRunId
    ? [
        {
          key: SmartFunctionActionDropdownKeys.PRINT_CONTENT,
          disabled: algorithmRun?.runStatus !== algorithmRunStatuses.COMPLETE,
          icon: <PrinterOutlined />,
          label: t(
            'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.printContent'
          ),
        },
      ]
    : []),
  ...(selectedWorkspace?.typeCode !== workspaceTypes.REGSUB &&
  selectedWorkspace?.typeCode !== workspaceTypes.DEL
    ? [
        {
          key: SmartFunctionActionDropdownKeys.EXPORT_RUN,
          disabled: algorithmRun?.runStatus !== algorithmRunStatuses.COMPLETE,
          icon: <ExportOutlined />,
          label: t(
            'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.exportRun'
          ),
        },
      ]
    : []),
]
