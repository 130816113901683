import { FormInstance } from 'antd'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { useWorkspaces } from '.'
import { riskReliefDeviationStatuses } from '../constants/constants'
import notificationService from '../services/notificationService'
import workspaceService from '../services/workspaceService'
import { Document } from '../store/reducers/searchReviewReducer'

interface UseProcessDeviationProps {
  form: FormInstance
  onSuccess?: () => void
}

const useProcessDeviation = ({ form, onSuccess }: UseProcessDeviationProps) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { refreshWorkspaceList } = useWorkspaces({ preventFetch: true })

  const addDeviationsToWorkspace = useCallback(
    async (
      searchReviewId: string,
      searchQueryId: string,
      documents: Document[]
    ) => {
      const selectedWorkspace = form.getFieldValue('workspace')

      if (!selectedWorkspace?.value) {
        notificationService.notificationError(
          t('processDeviationModal.missingData')
        )
        return
      }

      try {
        setConfirmLoading(true)
        const currentWorkspace = await workspaceService.getWorkspaceDetails({
          workspaceId: selectedWorkspace.value,
        })

        const existingConfig = currentWorkspace.config?.riskReliefConfig || {
          searchReviewIds: [],
          deviations: [],
        }

        if (!existingConfig.searchReviewIds.includes(searchReviewId)) {
          existingConfig.searchReviewIds.push(searchReviewId)
        }

        const newDeviations = documents
          // allow for "" but not undefined or null
          .filter((doc) => doc.summary !== undefined && doc.summary !== null)
          .map((doc) => ({
            searchReviewId,
            searchQueryId,
            searchResultId: doc.id,
            open: true,
            deviationStatus: riskReliefDeviationStatuses.OPENED,
            summary: doc.summary,
            // pass urn for sf
            urn: doc.urn,
          }))

        const uniqueDeviations = newDeviations.filter(
          (newDev) =>
            !existingConfig.deviations.some(
              (existingDev) =>
                existingDev.searchReviewId === newDev.searchReviewId &&
                existingDev.searchQueryId === newDev.searchQueryId &&
                existingDev.searchResultId === newDev.searchResultId
            )
        )

        if (uniqueDeviations.length === 0) {
          notificationService.notificationWarning(
            t('processDeviationModal.allDocumentsExist')
          )
          return
        }

        await workspaceService.updateWorkspaceDetails({
          workspaceId: selectedWorkspace.value,
          config: {
            ...currentWorkspace.config,
            riskReliefConfig: {
              searchReviewIds: existingConfig.searchReviewIds,
              deviations: [...existingConfig.deviations, ...uniqueDeviations],
            },
          },
        })

        const skippedCount = documents.length - uniqueDeviations.length
        if (skippedCount > 0) {
          notificationService.notificationSuccess(
            t('processDeviationModal.partialSuccess', {
              added: uniqueDeviations.length,
              skipped: skippedCount,
            })
          )
        } else {
          notificationService.notificationSuccess(
            t('processDeviationModal.submitSuccess')
          )
        }
        await refreshWorkspaceList()
        onSuccess?.()
      } catch (error) {
        console.error('Failed to update workspace:', error)
        notificationService.notificationError(
          t('processDeviationModal.submitError')
        )
      } finally {
        setConfirmLoading(false)
      }
    },
    [form, onSuccess, refreshWorkspaceList]
  )

  return {
    confirmLoading,
    addDeviationsToWorkspace,
  }
}

export default useProcessDeviation
