import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ConfirmDeleteModal,
  DeleteIconButton,
  ListPageHeader,
  ListPageLayout,
  PageContentDropdown,
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../components'
import {
  NO_DATA_PLACEHOLDER,
  dateFormat,
  urlList,
} from '../../constants/constants'
import { useWorkspaces } from '../../hooks'
import { apiService } from '../../services'
import notificationService from '../../services/notificationService'
import { RootState } from '../../store'
import { Workspace } from '../../store/reducers/workspaceReducer'
import { routePaths } from '../RootPage'
import CreateWorkspaceModal from './modals/CreateWorkspaceModal'

interface ExtendedWorkspace extends Workspace {
  key: string
}

const WorkspaceListPage = () => {
  const { currentUser } = useSelector((state: RootState) => state.user)
  const {
    workspaceList,
    loading,
    refreshWorkspaceList,
    exportWorkspaceListCsv,
  } = useWorkspaces({})
  const navigate = useNavigate()
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const formattedData: ExtendedWorkspace[] =
    useMemo(
      () =>
        workspaceList?.map((workspace: Workspace) => ({
          ...workspace,
          key: workspace.id,
        })),
      [workspaceList]
    ) || []

  const handleClick = (id: string) => {
    const selectedWorkspace = workspaceList?.find(
      (workspace) => workspace.id === id
    )
    if (selectedWorkspace) {
      navigate(`${routePaths.WORKSPACE_DETAILS}/${selectedWorkspace.id}`)
    }
  }

  const handleDeleteClick = useCallback((event: MouseEvent, id: string) => {
    event.stopPropagation()
    setDeleteId(id)
    setConfirmDeleteModalOpen(true)
  }, [])

  const handleConfirmDelete = () => {
    if (deleteId) {
      setDeleteLoading(true)
      apiService
        .deleteItem(`${urlList.WORKSPACES}/${deleteId}`, navigate)
        .then((response: Workspace) => {
          refreshWorkspaceList()
          setDeleteLoading(false)
          notificationService.notificationSuccess(
            t('workspaceListPage.deletedWorkspaceSuccessful')
          )
        })
        .catch((error: AxiosError | Error) => {
          console.error('axios fetch error', error)
          setDeleteLoading(false)
        })
    }
    setConfirmDeleteModalOpen(false)
    setDeleteId(undefined)
  }

  const columns: ColumnsType<Workspace> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '45%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Workspace Type'),
      dataIndex: 'typeName',
      key: 'workspaceType',
      width: '15%',
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: t('Privacy'),
      dataIndex: 'visibility',
      key: 'privacy',
      render: (privacy: string) => <>{t(privacy) || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: t('Last Update'),
      dataIndex: 'lastModifiedDate',
      key: 'lastModifiedDate',
      render: (lastModifiedDate) => (
        <>
          {lastModifiedDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {lastModifiedDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, { id, createdBy }) => (
        <>
          {(currentUser?.role?.permissions.some((permission) =>
            permission.includes('admin')
          ) ||
            currentUser?.id === createdBy) && (
            <DeleteIconButton
              tooltipTitle={t('workspaceListPage.deleteWorkspaceTooltip')}
              onClick={(e: MouseEvent) => handleDeleteClick(e, id)}
            />
          )}
        </>
      ),
    },
  ]

  const handleWorkspaceListExport = () => {
    exportWorkspaceListCsv()
  }

  return loading ? (
    <SectionLoadingState />
  ) : (
    <>
      <ListPageLayout>
        <ListPageHeader
          title={t('workspaceListPage.title', { count: formattedData.length })}
          actions={
            <>
              <CreateWorkspaceModal />
              <PageContentDropdown
                onExport={handleWorkspaceListExport}
                disabled={formattedData.length === 0}
              />
            </>
          }
        />

        <Table
          rowClassName="page-list-table-row clickable"
          columns={columns}
          dataSource={formattedData}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: () => {
                handleClick(record.id)
              }, // click row
            }
          }}
          scroll={{ y: 'calc(100vh - 155px)' }}
        />
      </ListPageLayout>
      <ConfirmDeleteModal
        open={confirmDeleteModalOpen}
        onConfirm={handleConfirmDelete}
        onCancel={() => setConfirmDeleteModalOpen(false)}
        loading={deleteLoading}
      >
        {t('workspaceListPage.confirmDeleteModalContent')}
      </ConfirmDeleteModal>
    </>
  )
}

export default WorkspaceListPage
