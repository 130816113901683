import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  NO_DATA_PLACEHOLDER,
  missingReferenceCategories,
} from '../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../hooks'
import { smartFunctionService } from '../../../../services'
import {
  AlgorithmRun,
  RefAIRunResult,
} from '../../../../store/reducers/workspaceReducer'
import WorkspaceStatistic from './WorkspaceStatistic'
import WorkspaceStatisticsPanelContainer from './WorkspaceStatisticsPanelContainer'

interface RefAiSmartFunctionStatistics {
  totalDocuments: number
  totalReferrers?: number
  referencesFound?: number
  referencesFoundPercentage?: number
  missingReferents?: number
  missingReferentsPercentage?: number
  excludedReferences?: number
  excludedReferencesPercentage?: number
  processedDocuments?: number
  processedDocumentsWordCount?: number | string
  totalRuns?: number
}

const RefAiSmartFunctionStatisticsPanel = () => {
  const dispatch = useDispatch()
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })
  const [statistics, setStatistics] = useState<RefAiSmartFunctionStatistics>()

  const mostRecentCompleteAlgorithmRun = useMemo(() => {
    if (!selectedWorkspace?.algorithmRuns) {
      return undefined
    }
    return selectedWorkspace?.algorithmRuns
      .filter(
        (obj) => obj.runStatus === 'COMPLETE' && obj.completedDate !== null
      )
      .reduce((prev, current) => {
        if (prev.completedDate && current.completedDate) {
          return Date.parse(prev.completedDate) >
            Date.parse(current.completedDate)
            ? prev
            : current
        } else if (prev.completedDate) {
          return prev
        } else {
          return current
        }
      }, selectedWorkspace.algorithmRuns[0])
  }, [selectedWorkspace])

  const calculateStatistics = useCallback(
    (res: AlgorithmRun) => {
      const refAIResult = res.runResult as RefAIRunResult
      const totalDocuments = refAIResult?.totalDocuments || 0
      const totalRuns = selectedWorkspace?.algorithmRuns?.length
      const processedDocuments = refAIResult?.processedDocument || 0
      const processedDocumentsWordCount =
        refAIResult?.totalTokensProcessed
          ?.toLocaleString()
          .replace(/,/g, ' ') || 0
      const totalReferrers = refAIResult?.numberOfReferrers
      const referencesFound =
        refAIResult?.referencesByDoc.reduce(
          (count, obj) => count + obj.refs.length,
          0
        ) || 0
      const missingReferents =
        refAIResult?.missingReferences.filter(
          (missingReference) =>
            missingReference.category !==
            missingReferenceCategories.IRRELEVANT_REFERENCES
        ).length || 0
      const excludedReferences =
        selectedWorkspace?.config?.excludedMissingReference?.length || 0
      const divisor = referencesFound + missingReferents + excludedReferences
      const referencesFoundPercentage =
        divisor > 0 ? (referencesFound / divisor) * 100 : 0
      const missingReferentsPercentage =
        divisor > 0 ? (missingReferents / divisor) * 100 : 0
      const excludedReferencesPercentage =
        divisor > 0 ? (excludedReferences / divisor) * 100 : 0

      return {
        totalDocuments,
        totalRuns,
        processedDocuments,
        processedDocumentsWordCount,
        totalReferrers,
        referencesFound,
        referencesFoundPercentage: Math.round(referencesFoundPercentage),
        missingReferents,
        missingReferentsPercentage: Math.round(missingReferentsPercentage),
        excludedReferences,
        excludedReferencesPercentage: Math.round(excludedReferencesPercentage),
      }
    },
    [selectedWorkspace]
  )

  useEffect(() => {
    if (mostRecentCompleteAlgorithmRun && selectedWorkspace?.id) {
      smartFunctionService
        .getWorkspaceAlgorithmRun({
          workspaceId: selectedWorkspace?.id,
          algorithmRunId: mostRecentCompleteAlgorithmRun.id,
        })
        .then((res) => {
          const values = calculateStatistics(res)
          setStatistics(values)
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [
    mostRecentCompleteAlgorithmRun,
    selectedWorkspace,
    calculateStatistics,
    dispatch,
  ])

  return (
    <WorkspaceStatisticsPanelContainer>
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.refAiStatistics.smartFunctions'
        )}
        value={statistics?.totalRuns || 0}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.refAiStatistics.totalDocuments'
        )}
        value={statistics?.totalDocuments || 0}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.refAiStatistics.processedDocuments'
        )}
        value={`${statistics?.processedDocuments || 0} (${
          statistics?.processedDocumentsWordCount || 0
        } ${t('Words')})`}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.refAiStatistics.totalReferrers'
        )}
        value={statistics?.totalReferrers || 0}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.refAiStatistics.referencesFound'
        )}
        value={
          `${statistics?.referencesFound || 0} (${
            statistics?.referencesFoundPercentage || 0
          }%)` || NO_DATA_PLACEHOLDER
        }
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.refAiStatistics.missingReferents'
        )}
        value={
          `${statistics?.missingReferents || 0} (${
            statistics?.missingReferentsPercentage || 0
          }%)` || NO_DATA_PLACEHOLDER
        }
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.refAiStatistics.excludedReferences'
        )}
        value={
          `${statistics?.excludedReferences || 0} (${
            statistics?.excludedReferencesPercentage || 0
          }%)` || NO_DATA_PLACEHOLDER
        }
      />
    </WorkspaceStatisticsPanelContainer>
  )
}

export default RefAiSmartFunctionStatisticsPanel
