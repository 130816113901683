import { NavigateFunction } from 'react-router-dom'
import {
  dataTypes,
  SearchReviewType,
  searchReviewTypes,
  urlList,
} from '../constants/constants'
import i18n from '../i18n/i18n'
import { SearchSource } from '../store/reducers/searchReviewReducer'
import { SearchMethod } from '../types/generalTypes'
import apiService from './apiService'

interface SearchParameters {
  dataType: string[]
  date: string
  language: string
  searchMethod: string
  searchQuery: string
  source: string
}

interface SearchBody {
  searchDescription: string
  searchParameters: SearchParameters
}

interface SaveSearchQueryInSearchReviewProps {
  navigate: NavigateFunction
  searchQuery?: string
  searchMethod?: SearchMethod
  sourceId?: string
  period?: string
  title?: string
  searchReviewId?: string
  reviewType?: SearchReviewType
}

const createSearchReviewFromSearchQuery = async ({
  navigate,
  searchQuery,
  searchMethod,
  sourceId,
  period,
  title,
  reviewType,
}: SaveSearchQueryInSearchReviewProps) => {
  const searchBody: SearchBody = {
    searchDescription: searchQuery || '',
    searchParameters: {
      dataType: [dataTypes.DOCUMENT],
      date: period || '',
      language: i18n.language,
      searchMethod: searchMethod || '',
      searchQuery: searchQuery || '',
      source: sourceId || '',
    },
  }

  const params = {
    searchBody,
    title,
    reviewType: reviewType || searchReviewTypes.LITERATURE_REVIEW,
  }

  return apiService
    .saveItem(urlList.SEARCH_REVIEWS, navigate, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios save search error', error)
      throw error
    })
}

const saveSearchQueryInSearchReview = async ({
  navigate,
  searchQuery,
  searchMethod,
  sourceId,
  period,
  searchReviewId,
}: SaveSearchQueryInSearchReviewProps) => {
  const searchBody: SearchBody = {
    searchDescription: searchQuery || '',
    searchParameters: {
      dataType: [dataTypes.DOCUMENT],
      date: period || '',
      language: i18n.language,
      searchMethod: searchMethod || '',
      searchQuery: searchQuery || '',
      source: sourceId || '',
    },
  }

  const params = {
    searchBody,
    title: undefined,
  }

  return apiService
    .updateItem(
      `${urlList.SEARCH_REVIEWS}/${searchReviewId}/newQuery`,
      navigate,
      params
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios save search error', error)
      throw error
    })
}
// }

interface SaveSearchQueryInclusion {
  searchReviewId: string
  includedSearchResults: string[]
  recordId: string
  navigate: NavigateFunction
}

const saveSearchQueryInclusion = async ({
  searchReviewId,
  includedSearchResults,
  recordId,
  navigate,
}: SaveSearchQueryInclusion) => {
  let includedList = includedSearchResults

  if (includedList?.includes(recordId)) {
    includedList = includedList.filter(
      (searchResult) => searchResult !== recordId
    )
  } else {
    includedList = [...includedList, recordId]
  }

  const params = {
    includedSearchResults: includedList,
  }

  return apiService
    .updateItem(`${urlList.SEARCH_REVIEWS}/${searchReviewId}`, navigate, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      apiService.notificationError(error)
      console.error('axios save search error', error)
      throw error
    })
}

interface UpdateSearchReviewDetailsProps {
  title: string
  description: string
  searchReviewId: string
  navigate: NavigateFunction
}

const updateSearchReviewDetails = async ({
  title,
  description,
  searchReviewId,
  navigate,
}: UpdateSearchReviewDetailsProps) => {
  const params = {
    title,
    description,
  }

  return apiService
    .updateItem(`${urlList.SEARCH_REVIEWS}/${searchReviewId}`, navigate, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios search error', error)
      throw error
    })
}

interface DeleteSearchReviewProps {
  searchReviewId: string
  navigate: NavigateFunction
}

const deleteSearchReview = async ({
  searchReviewId,
  navigate,
}: DeleteSearchReviewProps) => {
  return apiService
    .deleteItem(`${urlList.SEARCH_REVIEWS}/${searchReviewId}`, navigate)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios delete error', error)
      throw error
    })
}

const getSearchQuerySourceId = (
  searchQuery: string | SearchSource | undefined
) => {
  if (typeof searchQuery === 'string') {
    return searchQuery
  } else if (searchQuery !== undefined) {
    return searchQuery.id
  }
  return ''
}

const exportSearchReviewList = async () => {
  return apiService
    .fetchItems(`${urlList.SEARCH_REVIEWS}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface ExportSearchReviewToCsvProps {
  searchReviewId: string
}

const exportSearchReviewToCsv = async ({
  searchReviewId,
}: ExportSearchReviewToCsvProps) => {
  return apiService
    .fetchItems(`${urlList.SEARCH_REVIEWS}/${searchReviewId}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface ExportSearchReviewSearchQueryToCsvProps {
  searchReviewId: string
  searchQueryId: string
}

const exportSearchReviewSearchQueryToCsv = async ({
  searchReviewId,
  searchQueryId,
}: ExportSearchReviewSearchQueryToCsvProps) => {
  return apiService
    .fetchItems(
      `${urlList.SEARCH_REVIEWS}/${searchReviewId}/searchId=${searchQueryId}/csv`
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface SummarizeSearchQueryProps {
  searchReviewId: string
  resultId: string
}

const summarizeSearchQueryResult = ({
  searchReviewId,
  resultId,
}: SummarizeSearchQueryProps) => {
  return apiService
    .saveItem(
      `${urlList.SEARCH_REVIEWS}/${searchReviewId}/resultId=${resultId}/summary/process`
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const getSearchReview = async (searchReviewId: string) => {
  return apiService
    .fetchItems(`${urlList.SEARCH_REVIEWS}/${searchReviewId}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

export const getSearchReviewsBatch = async (searchReviewIds: string[]) => {
  const searchReviewIdsString = searchReviewIds.join(',')
  return apiService
    .fetchItems(`${urlList.SEARCH_REVIEWS}/ids=${searchReviewIdsString}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

export const searchReviewService = {
  createSearchReviewFromSearchQuery,
  deleteSearchReview,
  exportSearchReviewList,
  exportSearchReviewSearchQueryToCsv,
  exportSearchReviewToCsv,
  getSearchQuerySourceId,
  saveSearchQueryInclusion,
  saveSearchQueryInSearchReview,
  summarizeSearchQueryResult,
  updateSearchReviewDetails,
  getSearchReview,
  getSearchReviewsBatch,
}

export default searchReviewService
