import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

const ButtonContainer = styled.div`
  min-width: 32px;
`

interface BackButtonProps {
  url: string
  tooltipMessage?: string
}

const BackButton = ({ url, tooltipMessage }: BackButtonProps) => {
  const navigate = useNavigate()
  return (
    <Tooltip
      title={tooltipMessage || t('backButton.tooltipMessage')}
      placement="right"
    >
      <ButtonContainer>
        <Button
          icon={<ArrowLeftOutlined />}
          type="primary"
          onClick={() => navigate(url)}
        />
      </ButtonContainer>
    </Tooltip>
  )
}

export default BackButton
