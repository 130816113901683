import { Select } from 'antd'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../hooks'
import { SelectOption } from '../../../types/generalTypes'

interface SmartFunctionSelectRunProps {
  onChange?: (runId: string | undefined) => void
}

const SmartFunctionSelectRun = ({ onChange }: SmartFunctionSelectRunProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const { loading } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
  })
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    algorithmRunId
  )

  const handleChange = (value: string) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  const options: SelectOption[] = useMemo(
    () =>
      selectedWorkspace?.algorithmRuns?.map(
        (run, index) =>
          ({
            label: t('smartFunctionDetailsPage.selectRunLabel', {
              count: index + 1,
              total: selectedWorkspace.algorithmRuns?.length ?? 0,
            }),
            value: run.id,
          } as SelectOption)
      ) ?? [],
    [selectedWorkspace?.algorithmRuns]
  )

  return (
    <Select
      onChange={handleChange}
      value={selectedValue}
      options={options}
      placeholder={t('smartFunctionDetailsPage.selectRunLabel', {
        count: selectedWorkspace?.algorithmRuns?.length ?? 0,
        total: selectedWorkspace?.algorithmRuns?.length ?? 0,
      })}
      loading={loading}
    />
  )
}

export default SmartFunctionSelectRun
