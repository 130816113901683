import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavigateFunction } from 'react-router-dom'
import { SearchReviewType, urlList } from '../constants/constants'
import { apiService, searchReviewService } from '../services'
import notificationService from '../services/notificationService'
import { RootState } from '../store'
import {
  Document,
  SearchQuery,
  SearchReview,
  SearchSource,
  setDetailsCsv,
  setListCsv,
  setSearchQueryCsv,
  setSearchReviewList,
  setSearchReviewLoading,
  setSelectedSearchQuery,
  setSelectedSearchResultItem,
  setSelectedSearchReview,
} from '../store/reducers/searchReviewReducer'
import { SearchMethod } from '../types/generalTypes'
import useDownloadFile from './useDownloadFile'

interface UseSearchReviewProps {
  searchReviewId?: string
  searchId?: string
  documentId?: string
  preventFetch?: boolean
}

const useSearchReviews = ({
  searchReviewId,
  searchId,
  documentId,
  preventFetch = false,
}: UseSearchReviewProps) => {
  const dispatch = useDispatch()
  const downloadCsv = useDownloadFile()
  const loading = useSelector((state: RootState) => state.searchReview.loading)
  const searchReviewList: SearchReview[] | undefined = useSelector(
    (state: RootState) => state.searchReview.searchReviewList
  )
  const selectedSearchReview: SearchReview | undefined = useSelector(
    (state: RootState) => state.searchReview.selected
  )
  const selectedSearchQuery: SearchQuery | undefined = useSelector(
    (state: RootState) => state.searchReview.selectedSearchQuery
  )
  const selectedDocument: Document | undefined = useSelector(
    (state: RootState) => state.searchReview.selectedSearchResultItem
  )

  const updateSelectedItems = useCallback(
    (searchReviewList: SearchReview[]) => {
      if (searchReviewList) {
        const selectedSearchReview = searchReviewList.find(
          (review) => review.id === searchReviewId
        )
        if (selectedSearchReview) {
          dispatch(setSelectedSearchReview(selectedSearchReview))
        }
        if (searchId) {
          const selectedSearch = selectedSearchReview?.searchQueries?.find(
            (query) => query.searchEvent === searchId
          )
          if (selectedSearch) {
            dispatch(setSelectedSearchQuery(selectedSearch))
          }
          if (documentId) {
            const document = selectedSearch?.searchResults?.find(
              (searchResult) => searchResult.id === documentId
            )
            if (document) {
              dispatch(setSelectedSearchResultItem(document))
            }
          }
        }
      }
    },
    [dispatch, searchReviewId, searchId, documentId]
  )

  const fetchSearchReviewList = useCallback(
    (refreshForceCall = false) => {
      if (!loading && (!preventFetch || refreshForceCall)) {
        dispatch(setSearchReviewLoading(true))
        apiService
          .fetchItems(urlList.SEARCH_REVIEWS)
          .then((response: SearchReview[]) => {
            dispatch(setSearchReviewLoading(false))
            dispatch(setSearchReviewList(response))
            updateSelectedItems(response)
          })
          .catch((error: AxiosError | Error) => {
            console.error('axios fetch error', error)
            dispatch(setSearchReviewLoading(false))
          })
      }
    },
    [dispatch, loading, updateSelectedItems, preventFetch]
  )

  const refreshSearchReviewList = useCallback(() => {
    fetchSearchReviewList(true)
  }, [fetchSearchReviewList])

  useEffect(() => {
    fetchSearchReviewList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateSelectedSearchReview = useCallback(
    (selectedSearchReview: SearchReview) => {
      dispatch(setSelectedSearchReview(selectedSearchReview))
      const filteredSearchReviewList = searchReviewList
        ? searchReviewList.filter(
            (searchReview) => searchReview.id !== selectedSearchReview.id
          )
        : []
      const newSearchReviewList = [
        ...filteredSearchReviewList,
        selectedSearchReview,
      ]
      dispatch(setSearchReviewList(newSearchReviewList))
      updateSelectedItems(newSearchReviewList)
    },
    [dispatch, searchReviewList, updateSelectedItems]
  )

  const exportSearchReviewListCsv = useCallback(() => {
    if (searchReviewList) {
      searchReviewService
        .exportSearchReviewList()
        .then((response: string) => {
          const filename = 'search_review_list.csv'
          downloadCsv(response, filename)
          dispatch(setListCsv(response))
        })
        .catch((error: AxiosError) => {
          console.error('Error fetching search review list CSV data', error)
        })
    }
  }, [dispatch, downloadCsv, searchReviewList])

  const exportSearchReviewCsv = useCallback(
    (searchReviewId: string) => {
      if (searchReviewId) {
        searchReviewService
          .exportSearchReviewToCsv({ searchReviewId })
          .then((response: string) => {
            const filename = `${selectedSearchReview?.title}.csv`
            downloadCsv(response, filename)
            dispatch(setDetailsCsv(response))
          })
          .catch((error: AxiosError) => {
            console.error('Error fetching search review CSV data', error)
          })
      }
    },
    [dispatch, downloadCsv, selectedSearchReview?.title]
  )

  const exportSearchReviewSearchQueryCsv = useCallback(
    (searchReviewId: string, searchQueryId: string) => {
      searchReviewService
        .exportSearchReviewSearchQueryToCsv({
          searchReviewId,
          searchQueryId,
        })
        .then((response: string) => {
          const filename = `${selectedSearchReview?.title}_${selectedSearchQuery?.searchText}_${selectedSearchQuery?.searchDate}.csv`
          downloadCsv(response, filename)
          dispatch(setSearchQueryCsv(response))
        })
        .catch((error: AxiosError) => {
          console.error('Error fetching search query CSV data', error)
        })
    },
    [dispatch, downloadCsv, selectedSearchReview, selectedSearchQuery]
  )

  interface SummarizeSearchReviewSearchQueryResultProps {
    searchReviewId: string
    resultId: string
  }

  const summarizeSearchReviewSearchQueryResult = useCallback(
    ({
      searchReviewId,
      resultId,
    }: SummarizeSearchReviewSearchQueryResultProps) => {
      searchReviewService
        .summarizeSearchQueryResult({
          searchReviewId,
          resultId,
        })
        .then((response: SearchReview) => {
          if (response) {
            updateSelectedSearchReview(response)
            notificationService.notificationSuccess(
              t('searchReviewDetailsPage.summarizationStarted')
            )
          }
        })
        .catch((error: AxiosError) => {
          console.error('Error fetching summary for search result', error)
        })
    },
    [updateSelectedSearchReview]
  )

  interface CreateSearchReviewProps {
    query: string
    searchMethod?: SearchMethod
    sourceId: string
    period: string
    reviewTitle: string
    reviewType: SearchReviewType
    navigate: NavigateFunction
  }

  const createSearchReview = useCallback(
    ({
      query,
      searchMethod,
      sourceId,
      period,
      reviewTitle,
      reviewType,
      navigate,
    }: CreateSearchReviewProps) => {
      return searchReviewService
        .createSearchReviewFromSearchQuery({
          reviewType,
          navigate,
          searchQuery: query,
          searchMethod,
          sourceId,
          period,
          title: reviewTitle,
        })
        .then((response: SearchReview) => {
          dispatch(setSelectedSearchReview(response))
          refreshSearchReviewList()
          return response
        })
        .catch((error) => {
          console.error('axios fetch error', error)
          return error
        })
    },
    [dispatch, refreshSearchReviewList]
  )

  interface AddToExistingSearchReview {
    query: string
    searchMethod?: SearchMethod
    sourceId: string
    period: string
    searchReviewId: string
    navigate: NavigateFunction
  }

  const addToExistingSearchReview = useCallback(
    ({
      query,
      searchMethod,
      sourceId,
      period,
      searchReviewId,
      navigate,
    }: AddToExistingSearchReview) => {
      return searchReviewService
        .saveSearchQueryInSearchReview({
          searchQuery: query,
          searchMethod,
          sourceId,
          period,
          searchReviewId,
          navigate,
        })
        .then((response: SearchReview) => {
          dispatch(setSelectedSearchReview(response))
          refreshSearchReviewList()
          return response
        })
        .catch((error) => {
          console.error('axios fetch error', error)
          return error
        })
    },
    [dispatch, refreshSearchReviewList]
  )

  interface SaveSearchQueryInclusionProps {
    recordId: string
    navigate: NavigateFunction
  }

  const saveSearchQueryInclusion = useCallback(
    ({ recordId, navigate }: SaveSearchQueryInclusionProps) => {
      return searchReviewService
        .saveSearchQueryInclusion({
          searchReviewId: selectedSearchReview?.id || '',
          includedSearchResults:
            selectedSearchReview?.includedSearchResults || [],
          recordId,
          navigate,
        })
        .then((response: SearchReview) => {
          updateSelectedSearchReview(response)
          notificationService.notificationSuccess(
            t('searchReviewSearchDetailsPage.saveDocumentSuccessful') || ''
          )
          return response
        })
        .catch((error) => {
          console.error('axios fetch error', error)
          return error
        })
    },
    [selectedSearchReview, updateSelectedSearchReview]
  )

  const deleteSearchReview = useCallback(
    (searchReviewId: string, navigate: NavigateFunction) => {
      return searchReviewService
        .deleteSearchReview({
          searchReviewId: searchReviewId,
          navigate,
        })
        .then((response) => {
          refreshSearchReviewList()
          return response
        })
        .catch((error: AxiosError | Error) => {
          console.error('axios fetch error', error)
          return error
        })
    },
    [refreshSearchReviewList]
  )

  interface UpdateSearchReviewDetailsProps {
    title: string
    description: string
    searchReviewId: string
    navigate: NavigateFunction
  }

  const updateSearchReviewDetails = useCallback(
    ({
      title,
      description,
      searchReviewId,
      navigate,
    }: UpdateSearchReviewDetailsProps) => {
      return searchReviewService
        .updateSearchReviewDetails({
          title,
          description,
          searchReviewId,
          navigate,
        })
        .then((response: SearchReview) => {
          return response
        })
        .catch((error: AxiosError | Error) => {
          console.error('axios fetch error', error)
          return error
        })
    },
    []
  )

  const getSearchQuerySourceIdForSearchReview = useCallback(
    (searchSource: string | SearchSource | undefined) => {
      return searchReviewService.getSearchQuerySourceId(searchSource)
    },
    []
  )

  const getSearchReviewsBatch = useCallback((searchReviewIds: string[]) => {
    return searchReviewService.getSearchReviewsBatch(searchReviewIds)
  }, [])

  return {
    exportSearchReviewListCsv,
    exportSearchReviewCsv,
    exportSearchReviewSearchQueryCsv,
    searchReviewList,
    loading,
    refreshSearchReviewList,
    selectedDocument,
    selectedSearchReview,
    selectedSearchQuery,
    summarizeSearchReviewSearchQueryResult,
    updateSelectedSearchReview,
    createSearchReview,
    addToExistingSearchReview,
    saveSearchQueryInclusion,
    deleteSearchReview,
    updateSearchReviewDetails,
    getSearchQuerySourceIdForSearchReview,
    getSearchReviewsBatch,
  }
}
export default useSearchReviews
